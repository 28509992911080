export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
    loading: true,
  };
};

export const authenticate = (payload) => {
  console.log('authenticate', payload);
  return {
    type: LOGIN_REQUEST,
    payload,
  };
};

export const authenticateResponse = (type, data) => ({ type, data });

export const registerUser = (payload) => {
  console.log('registerUser', payload);
  return {
    type: REGISTER_REQUEST,
    payload,
  };
};

export const registerRequest = () => {
  return {
    type: REGISTER_REQUEST,
    loading: true,
  };
};

export const registerUserResponse = (type, data) => ({ type, data });

export const logoutReq = () => {
  console.log('logout request');
  return {
    type: LOGOUT_REQUEST,
  };
};

export const logoutResponse = (type) => ({ type });

export const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
    loading: true,
  };
};

export const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
    loading: false,
  };
};
