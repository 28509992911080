import React, { useState } from 'react';
import 'moment-timezone';
import ReactEChartsCore from 'echarts-for-react/lib/core';
//import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import {
  ToolboxComponent,
  ToolboxComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  DataZoomComponent,
  DataZoomComponentOption,
} from 'echarts/components';
import { LineChart, LineSeriesOption, BarChart, BarSeriesOption } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { SelectButton } from 'primereact/selectbutton';
import '../../../App.css';
import moment from 'moment-timezone';

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  LineChart,
  BarChart,
  CanvasRenderer,
  UniversalTransition,
]);

type EChartsOption = echarts.ComposeOption<
  | ToolboxComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | LegendComponentOption
  | DataZoomComponentOption
  | LineSeriesOption
  | BarSeriesOption
>;

const DeviceLogsChart = ({ payload }) => {
  const timeSpans = ['Day', 'Week', 'Month', 'All'];
  const [selectedTimeSpan, setSelectedTimeSpan] = useState(timeSpans[0]);
  const [startTimeOffset, setStartTimeOffset] = useState(new Date());
  const [endTimeOffset, setEndTimeOffset] = useState(new Date());
  const [showPh, setShowPh] = useState(true);
  const [showOrp, setShowOrp] = useState(true);
  const [showSalinity, setShowSalinity] = useState(true);
  const [showTemperature, setShowTemperature] = useState(true);
  const [showVsdw, setShowVsdw] = useState(true);
  const [showFlow, setShowFlow] = useState(true);
  let start = new Date();
  let end = new Date();
  let filteredDate = [];
  let filteredPh = [];
  let filteredOrp = [];
  let filteredSalinity = [];
  let filteredFlow = [];
  let filteredTemperature = [];
  let filteredVsdw = [];

  const nextTimeSpan = () => {
    switch (selectedTimeSpan) {
      case 'Day':
        start = moment(startTimeOffset).add(1, 'd').toDate();
        end = moment(endTimeOffset).add(1, 'd').toDate();
        break;
      case 'Week':
        start = moment(startTimeOffset).add(1, 'w').toDate();
        end = moment(endTimeOffset).add(1, 'w').toDate();
        break;
      case 'Month':
        start = moment(startTimeOffset).add(1, 'M').toDate();
        end = moment(endTimeOffset).add(1, 'M').toDate();
        break;
    }
    setStartTimeOffset(start);
    setEndTimeOffset(end);
  };

  const previousTimeSpan = () => {
    switch (selectedTimeSpan) {
      case 'Day':
        start = moment(startTimeOffset).subtract(1, 'd').toDate();
        end = moment(endTimeOffset).subtract(1, 'd').toDate();
        break;
      case 'Week':
        start = moment(startTimeOffset).subtract(1, 'w').toDate();
        end = moment(endTimeOffset).subtract(1, 'w').toDate();
        break;
      case 'Month':
        start = moment(startTimeOffset).subtract(1, 'M').toDate();
        end = moment(endTimeOffset).subtract(1, 'M').toDate();
        break;
    }
    setStartTimeOffset(start);
    setEndTimeOffset(end);
  };

  const onChangeDateFilterType = (filterType) => {
    switch (filterType) {
      case 'Day':
        start = endTimeOffset;
        break;
      case 'Week':
        start = moment(endTimeOffset).subtract(1, 'w').toDate();
        break;
      case 'Month':
        start = moment(endTimeOffset).subtract(1, 'M').toDate();
        break;
      default:
        end = moment(Date()).toDate();
        start = moment(endTimeOffset).subtract(6, 'M').toDate();
        break;
    }
    setStartTimeOffset(start);
    setSelectedTimeSpan(filterType);
  };

  console.log('startDate', startTimeOffset);
  console.log('endDate', endTimeOffset);
  const chartData = payload.data.chartData;
  const serialNumber = payload.serialNumber;
  let date = [];
  (chartData.samplesAt || []).forEach((element) => {
    const dt = new Date(element);
    date.push(dt);
  });

  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate());
  for (let index = date.length - 1; index >= 0; index--) {
    const dt = new Date(date[index]);
    if (
      moment(dt).isBetween(moment(startTimeOffset), moment(endTimeOffset)) ||
      moment(dt).isSame(moment(startTimeOffset), 'day')
    ) {
      filteredDate.unshift(dt.toLocaleString());
      filteredPh.unshift(chartData.ph[index]);
      filteredOrp.unshift(chartData.orp[index]);
      filteredSalinity.unshift(chartData.salinity[index]);
      filteredFlow.unshift(chartData.flowStatus[index]);
      filteredTemperature.unshift(chartData.temperature[index]);
      filteredVsdw.unshift(chartData.vsdw[index]);
    }
  }

  console.log('Start Date:', start.getDate());
  const colors = ['#5470C6', '#91CC75', '#EE6666', '#997c04', '#5D6556', '#2f4554'];

  const options: EChartsOption = {
    color: colors,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    grid: {
      right: '20%',
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        restore: { show: true },
        saveAsImage: { show: true, title: 'download', type: 'png', name: `DeviceLogs-${serialNumber}` },
      },
    },
    legend: {
      data: ['PH', 'ORP', 'Salinity', 'Temperature', 'Flow status', 'Vsdw'],
      selected: {
        PH: showPh,
        ORP: showOrp,
        Salinity: showSalinity,
        Temperature: showTemperature,
        Vsdw: showVsdw,
        'Flow status': showFlow,
      },
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: true,
        },
        data: filteredDate,
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'PH',
        position: 'left',
        alignTicks: true,
        offset: 40,
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[0],
          },
        },
        axisLabel: {
          formatter: '{value}',
        },
        min: 6,
        max: 9,
        axisPointer: {
          label: {
            precision: 1,
          },
        },
      },
      {
        type: 'value',
        name: 'ORP',
        position: 'left',
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[1],
          },
        },
        axisLabel: {
          formatter: '{value} mV',
        },
      },
      {
        type: 'value',
        name: 'Salinity',
        position: 'right',
        offset: 160,
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[2],
          },
        },
        axisLabel: {
          formatter: '{value} ppm',
        },
      },
      {
        type: 'value',
        name: 'Temperature',
        position: 'right',
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[3],
          },
        },
        axisLabel: {
          formatter: '{value} °C',
        },
        min: 0,
        max: 'dataMax',
      },
      {
        type: 'value',
        name: 'Flow status',
        position: 'right',
        alignTicks: false,
        offset: 80,
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[3],
          },
        },
        axisLabel: {
          formatter: '{value}',
        },
        min: 0,
        max: 'dataMax',
      },
      {
        type: 'value',
        name: 'Vsdw',
        position: 'left',
        alignTicks: false,
        offset: 90,
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[5],
          },
        },
        axisLabel: {
          formatter: '{value}',
        },
        min: 0,
        max: 'dataMax',
      },
    ],
    series: [
      {
        name: 'PH',
        type: 'line',
        yAxisIndex: 0,
        data: filteredPh,
      },
      {
        name: 'ORP',
        type: 'line',
        yAxisIndex: 1,
        data: filteredOrp,
      },
      {
        name: 'Salinity',
        type: 'line',
        yAxisIndex: 2,
        data: filteredSalinity,
      },
      {
        name: 'Temperature',
        type: 'line',
        yAxisIndex: 3,
        data: filteredTemperature,
      },
      {
        name: 'Flow status',
        type: 'line',
        yAxisIndex: 4,
        data: filteredFlow,
      },
      {
        name: 'Vsdw',
        type: 'line',
        yAxisIndex: 5,
        data: filteredVsdw,
      },
    ],
    dataZoom: [
      {
        textStyle: {
          color: '#8392A5',
        },
        dataBackground: {
          areaStyle: {
            color: '#8392A5',
          },
          lineStyle: {
            opacity: 0.8,
            color: '#8392A5',
          },
        },
        brushSelect: true,
      },
      {
        type: 'inside',
      },
    ],
  };

  const onChartClick = (params) => {
    setShowPh(params.selected.PH);
    setShowOrp(params.selected.ORP);
    setShowFlow(params.selected['Flow status']);
    setShowSalinity(params.selected.Salinity);
    setShowTemperature(params.selected.Temperature);
    setShowVsdw(params.selected.Vsdw);
  };

  const onEvents = {
    legendselectchanged: onChartClick,
  };

  return (
    <div>
      <ReactEChartsCore
        echarts={echarts}
        option={options}
        notMerge={true}
        lazyUpdate={true}
        style={{ height: 600 }}
        onEvents={onEvents}
      />
      <h5 className="centered">Date Range</h5>
      <SelectButton
        value={selectedTimeSpan}
        options={timeSpans}
        onChange={(e) => onChangeDateFilterType(e.value)}
        className="centered"
      />
      <br />
      <div className="centered">
        <button name="Previous" onClick={previousTimeSpan}>
          {'<<'}
        </button>
        <button name="Next" onClick={nextTimeSpan}>
          {'>>'}
        </button>
      </div>
    </div>
  );
};
export default DeviceLogsChart;
