import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import queryString from 'query-string';
import { Messages } from 'primereact/messages';
import { ProgressSpinner } from 'primereact/progressspinner';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import isNull from 'lodash/fp/isNull';

import {
  getDeviceDetailsAPI,
  detachDeviceFromUser,
  attachDeviceToUser,
  deviceLogsAPI,
  getLatestStableVersions,
  getLatestBetaVersions,
  updateDeviceAPI,
  deviceHistoryAPI,
  deviceCurrentShadowAPI,
  deviceAlarmsAPI,
  enableMockData,
  chartDeviceLogsAPI,
} from '../../../api/service/DeviceService';
import { PRODUCT, ENV_LIST } from '../../../api/routes/route';
import { UpdateDevicePayload } from '../../../api/service/service.types';
import DeviceParams from '../../components/customForms/DeviceParams';
import { mockDeviceDetails, mockDeviceLogs, mockShadowData, mockAlarms } from '../../../storage/mockData';
import DeviceLogsChart from '../../components/charts/DeviceLogsChart';

import './DeviceDetails.scss';

export const DeviceDetails = () => {
  const [device, setDevice] = useState<any | null>({});
  const [deviceShadow, setDeviceShadow] = useState<any | null>({});
  const [deviceLogs, setDeviceLogs] = useState<any | null>([]);
  const [alarms, setAlarms] = useState<any | null>([]);
  const [chartData, setChartData] = useState<any | null>([]);
  const [loading, setLoading] = useState(true);
  const mountedRef = useRef(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [detachUser, setDetachUser] = useState('');
  const [refreshPage, setRefreshPage] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [alarmDialogVisible, setAlarmDialogVisible] = useState(false);
  const [chartDialogVisible, setChartDialogVisible] = useState(false);
  const [fwVersions, setFwVersions] = useState([]);
  const [nipperVersions, setNipperVersions] = useState([]);
  const [selectedFw, setSelectedFw] = useState<any>(null);
  const [selectedNipper, setSelectedNipper] = useState<any>(null);
  const [selectedEnv, setSelectedEnv] = useState<any>(ENV_LIST.STABLE);
  const [selectedType, setSelectedType] = useState<any>(PRODUCT.LIFEGUARD);
  const [errorFwMessage, setErrorFwMessage] = useState(false);
  const [showFwMessage, setShowFwMessage] = useState(false);
  const [fwMessage, setFwMessage] = useState('');
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [deviceHistory, setDeviceHistory] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const isMounted = useRef(false);
  const msg = useRef(null);
  const op = useRef<OverlayPanel>(null);
  const fwOp = useRef<OverlayPanel>(null);
  const queryParams = queryString.parse(window.location.search);
  const envs = [ENV_LIST.STABLE, ENV_LIST.BETA];

  const [alarmFilters, setAlarmFilters] = useState<any | null>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    type: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    errorId: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    value: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    isRead: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  });

  useEffect(() => {
    isMounted.current = true;
    if (!queryParams?.sn) {
      msg.current.show({
        severity: 'error',
        sticky: true,
        content: <React.Fragment>"Invalid URL. Please contact admin"</React.Fragment>,
      });
    } else {
      setLoading(true);
      if (enableMockData()) {
        /* Mock data starts from here */
        const reducedRes = mockDeviceLogs.slice(0, -3);
        setDevice(mockDeviceDetails);
        setDeviceLogs(reducedRes);
        setDeviceShadow(mockShadowData.response);
        setLoading(false);
        /* Mock data ends here */
      } else {
        apiCalls();
      }
    }
    return () => {
      console.log('unmount called at deviceDetails');
      mountedRef.current = false;
    };
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const apiCalls = async () => {
    const payload: string[] = [];
    const genericError = 'Something went wrong. Please try again later';
    payload.push(queryParams?.sn.toString());
    try {
      const deviceDetails = await getDeviceDetailsAPI(payload);
      const deviceLogs = await deviceLogsAPI(queryParams?.sn.toString());
      const deviceShadow = await deviceCurrentShadowAPI(queryParams?.sn.toString());
      console.log('deviceDetails - deviceLogs - deviceShadow : ', deviceDetails, deviceLogs, deviceShadow);
      if (deviceDetails?.data?.response?.devices) {
        setDevice(deviceDetails?.data?.response?.devices[0]);
      }
      if (deviceLogs?.data?.response) {
        const reducedRes = deviceLogs?.data?.response.slice(0, -3);
        setDeviceLogs(reducedRes);
      }
      if (deviceShadow?.data?.response) {
        setDeviceShadow(deviceShadow?.data?.response);
        setLoading(false);
      } else {
        const errorMessage =
          deviceDetails?.error?.message || deviceLogs?.error?.message || deviceShadow?.error?.message || genericError;
        throw new Error(errorMessage);
      }
    } catch (error) {
      msg.current.show({
        severity: 'error',
        sticky: true,
        content: <React.Fragment>{error.message ? error.message : genericError}</React.Fragment>,
      });
    }
  };

  useEffect(() => {
    setSelectedFw(null);
    setSelectedNipper(null);
    if (selectedEnv === ENV_LIST.STABLE) {
      // Latest Stable lifeguard firmware versions
      getLatestStableVersions(PRODUCT.LIFEGUARD).then((payload) => {
        console.log('getLatestStableVersions lifeguard: ', payload);
        if (payload?.data?.response) {
          return setFwVersions(payload?.data?.response);
        }
        return 'Error retrieving Devices';
      });

      // Latest Stable nipper versions
      getLatestStableVersions(PRODUCT.NIPPER).then((payload) => {
        console.log('getLatestStableVersions nipper: ', payload);
        if (payload?.data?.response) {
          return setNipperVersions(payload?.data?.response);
        }
        return 'Error retrieving Devices';
      });
    } else {
      // Latest beta lifeguard firmware versions
      getLatestBetaVersions(PRODUCT.LIFEGUARD).then((payload) => {
        console.log('getLatestBetaVersions: ', payload);
        if (payload?.data?.response) {
          return setFwVersions(payload?.data?.response);
        }
        return 'Error retrieving Devices';
      });

      // Latest beta nipper versions
      getLatestBetaVersions(PRODUCT.NIPPER).then((payload) => {
        console.log('getLatestBetaVersions nipper: ', payload);
        if (payload?.data?.response) {
          return setNipperVersions(payload?.data?.response);
        }
        return 'Error retrieving Devices';
      });
    }
    return () => {
      mountedRef.current = false;
    };
  }, [selectedEnv]); // eslint-disable-line react-hooks/exhaustive-deps
  const globalFilterFunc = (e) => {
    let filterValue = e.target.value;
    if (e.target.value === 'unread') {
      filterValue = false;
    }
    if (e.target.value === 'read') {
      filterValue = true;
    }
    setGlobalFilter(filterValue);
  };
  const getHeader = () => {
    return (
      <div className="text-right">
        <div className="p-input-icon-left">
          <i className="pi pi-search"></i>
          <InputText type="search" onInput={(e) => globalFilterFunc(e)} placeholder="Global Search" />
        </div>
      </div>
    );
  };

  const header = getHeader();
  const detachBody = (rowData) => {
    return (
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-danger p-button-text"
        onClick={() => onDetachUser(rowData)}
      />
    );
  };
  const onRefresh = () => {
    setRefreshPage(!refreshPage);
  };
  const onDetachUser = (rowData) => {
    console.log('onDeleteUser event:', rowData);
    setMessage(`Are you sure you want to detach user ${rowData.email} from this device?`);
    setShowMessage(true);
    setDetachUser(rowData.email);
  };

  const onAttachUser = (data) => {
    console.log('onAttachUser event:', data);
    setButtonLoading(true);
    msg.current.clear();
    attachDeviceToUser({ email: data.email, serialNumber: device?.serialNumber })
      .then((res) => {
        console.log('onAttachUser: ', res);
        if (isMounted.current) {
          op.current?.hide();
        }
        if (!res.success && res?.error?.message) {
          msg.current.show({
            severity: 'error',
            sticky: true,
            content: <React.Fragment>{res?.error?.message}</React.Fragment>,
          });
        } else {
          msg.current.show({
            severity: 'success',
            sticky: true,
            content: <React.Fragment>User ({data.email}) attached successfully</React.Fragment>,
          });
        }
        setButtonLoading(false);
        setRefreshPage(!refreshPage);
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  };

  const onConfirmDetach = async () => {
    console.log('onConfirmDetach :', detachUser);
    setButtonLoading(true);
    detachDeviceFromUser({ email: detachUser, serialNumber: device?.serialNumber }).then((res) => {
      console.log('detachDeviceFromUser: ', res);
      setButtonLoading(false);
      setShowMessage(false);
      setRefreshPage(!refreshPage);
      setMessage('');
    });
  };

  const onCancelDetach = () => {
    setMessage('');
    setShowMessage(false);
    setDetachUser('');
  };

  const openDialog = () => {
    setDialogVisible(true);
    setLoadingHistory(true);
    deviceHistoryAPI(queryParams?.sn.toString()).then((res) => {
      setLoadingHistory(false);
      console.log('history', res);
      if (res?.data?.response[0]?.DeviceHistories) {
        setDeviceHistory(res?.data?.response[0]?.DeviceHistories);
      } else if (res?.error?.message) {
        msg.current.show({
          severity: 'error',
          sticky: true,
          content: <React.Fragment>{res?.error?.message}</React.Fragment>,
        });
      }
    });
  };

  const openAlarmsDialog = () => {
    setAlarmDialogVisible(true);
    setLoadingHistory(true);
    if (enableMockData()) {
      setAlarms(mockAlarms);
      setLoadingHistory(false);
    } else {
      deviceAlarmsAPI(queryParams?.sn.toString()).then((res) => {
        setLoadingHistory(false);
        console.log('deviceAlarmsAPI', res);
        if (res?.data?.response) {
          setAlarms(res?.data?.response);
        } else if (res?.error?.message) {
          msg.current.show({
            severity: 'error',
            sticky: true,
            content: <React.Fragment>{res?.error?.message}</React.Fragment>,
          });
        }
      });
    }
  };

  const openChartDialog = () => {
    setLoadingHistory(true);
    setButtonLoading(true);
    chartDeviceLogsAPI(queryParams?.sn.toString()).then((res) => {
      console.log('chartDeviceLogsAPI', res);
      setButtonLoading(false);
      setLoadingHistory(false);
      if (res?.data?.response) {
        setChartData(res?.data?.response);
        setChartDialogVisible(true);
      } else if (res?.error?.message) {
        msg.current.show({
          severity: 'error',
          sticky: true,
          content: <React.Fragment>{res?.error?.message}</React.Fragment>,
        });
      }
    });
  };

  const closeDialog = () => {
    setDialogVisible(false);
    setAlarmDialogVisible(false);
    setChartDialogVisible(false);
  };

  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button label="CANCEL" className="p-button-danger" onClick={onCancelDetach} />
      <Button label="DETACH" className="p-button-warning" onClick={onConfirmDetach} loading={buttonLoading} />
    </div>
  );

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>;
  };
  const defaultValues = {
    email: '',
  };

  const onFWChange = (e: { value: any }) => {
    setSelectedFw(e.value);
  };
  const onNipperChange = (e: { value: any }) => {
    setSelectedNipper(e.value);
  };
  const onEnvChange = (e: { value: any }) => {
    console.log('onEnvChange ', e);
    setSelectedEnv(e.value);
  };

  const onSubmitFW = () => {
    console.log('values', selectedFw, selectedEnv, selectedNipper, selectedType);
    if (selectedType === PRODUCT.LIFEGUARD && isNull(selectedFw)) {
      setErrorFwMessage(true);
      setFwMessage('FIRMWARE version not selected. Please select and hit UPDATE');
    } else if (selectedType === PRODUCT.NIPPER && isNull(selectedNipper)) {
      setErrorFwMessage(true);
      setFwMessage('NIPPER version not selected. Please select and hit UPDATE');
    } else {
      setShowFwMessage(true);
      if (selectedType === PRODUCT.NIPPER) {
        setFwMessage(`Are you sure you want to update selected devices to ${selectedEnv} Nipper v${selectedNipper}?`);
      } else {
        setFwMessage(`Are you sure you want to update selected devices to ${selectedEnv} Firmware v${selectedFw}?`);
      }
    }
  };

  const onUpdateFw = () => {
    setLoading(true);
    console.log('onUpdate: ', selectedFw, selectedEnv, selectedNipper, selectedType);
    setShowFwMessage(false);
    msg.current.clear();
    const payload: UpdateDevicePayload = {
      serialNumbers: [device?.serialNumber],
      firmwareVersion: selectedType === PRODUCT.LIFEGUARD ? selectedFw : selectedNipper,
      product: selectedType,
      releaseVersion: selectedEnv,
    };
    console.log('updateDeviceAPI: ', payload);
    updateDeviceAPI(payload).then((res) => {
      setLoading(false);
      console.log('updateDeviceAPI resp: ', res);
      if (isMounted.current) {
        op.current?.hide();
      }
      if (!res.success && res?.error?.message) {
        msg.current.show({
          severity: 'error',
          sticky: true,
          content: <React.Fragment>{res?.error?.message}</React.Fragment>,
        });
      } else {
        msg.current.show({
          severity: 'success',
          sticky: true,
          content: <React.Fragment>Firmware update requested</React.Fragment>,
        });
      }
    });
  };

  const dialogFwFooter = (
    <div className="flex justify-content-center">
      <Button label="CANCEL" className="p-button-danger" onClick={() => setShowFwMessage(false)} />
      <Button label="UPDATE" className="p-button-warning" onClick={onUpdateFw} />
    </div>
  );

  const errorDialogFwFooter = (
    <div className="flex justify-content-center">
      <Button label="OK" className="p-button-warning" autoFocus onClick={() => setErrorFwMessage(false)} />
    </div>
  );

  const dateTimeBodyTemplate = (rowData) => {
    if (rowData.updatedAt) {
      return new Date(rowData.updatedAt).toLocaleString();
    }
    return new Date(rowData.date).toLocaleString();
  };
  const dateBodyTemplate = (rowData) => {
    return new Date(rowData.date).toLocaleDateString();
  };

  const alarmDateBodyTemplate = (rowData) => {
    return new Date(rowData.createdAt).toLocaleString();
  };

  const alarmReadBodyTemplate = (rowData) => {
    return rowData.isRead ? 'read' : 'unread';
  };

  const editedDeviceParam = (param) => {
    console.log('editedDeviceParam', param);
    if (param.refreshRequest) {
      setRefreshPage(!refreshPage);
    }
  };

  const connStatusFilterTemplate = (options) => {
    return (
      <div>
        <span>Read </span>
        <TriStateCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} />
      </div>
    );
  };

  const chartHeader = `Device Log view : ${queryParams?.sn.toString()}`;
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  if (loading) {
    return (
      <div>
        <Messages ref={msg} />
        <div className="grid">
          <ProgressSpinner />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="card">
          <h5>
            <b>Device Details</b>
            <Messages ref={msg} />
          </h5>
          <br />

          <div className="grid">
            <div className="col-12 md:col-6 lg:col-3">
              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-500 font-medium mb-3">Serial Number</span>
                    <div className="text-900 font-medium text-l">{device?.serialNumber}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-500 font-medium mb-3">Installed version #</span>
                    <div className="text-00 font-medium text-l">
                      FW: {device?.firmwareVersion}, Nipper:
                      {device.nipperVersion === '' ? ' Not available' : device.nipperVersion}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-500 font-medium mb-3">Active Modes</span>
                    <div className="text-900 font-medium text-l">{(device?.modes || []).toString()}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-500 font-medium mb-3">Connection status</span>
                    <div className="text-900 font-medium text-l">
                      {device?.isConnected ? (
                        <span className="text-green-500 font-medium">Connected</span>
                      ) : (
                        <span className="text-pink-500 font-medium">Not Connected</span>
                      )}{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="card">
            <div className="flex justify-content-evenly flex-wrap card-container orange-container">
              <Button
                label="Chart view"
                className="p-button-warning"
                onClick={openChartDialog}
                loading={buttonLoading}
              />
              <Button label="History" className="p-button-warning" onClick={openDialog} />
              <Button label="Recent Alarms" className="p-button-warning" onClick={openAlarmsDialog} />
              <Button label="Update FW" className="p-button-warning" onClick={(e) => fwOp.current?.toggle(e)} />
              <Button icon="pi pi-refresh" label="Refresh" onClick={onRefresh} />
            </div>
          </div>
          <br />
          <div className="grid">
            <div className="col-12 xl:col-6">
              <div className="card">
                <div className="flex justify-content-between align-items-center mb-5">
                  <h5>Connected Users</h5>
                  <div>
                    <Button
                      type="button"
                      className="p-button-outlined p-button-warning"
                      label={'Attach user'}
                      onClick={(e) => op.current?.toggle(e)}
                      aria-haspopup
                      aria-controls="overlay_panel"
                    />
                  </div>
                </div>
                <DataTable
                  value={device.deviceUser}
                  rows={5}
                  responsiveLayout="scroll"
                  scrollHeight="350px"
                  loading={loading}
                  scrollDirection="both"
                >
                  <Column field="firstname" header="Name" sortable style={{ width: '35%' }} />
                  <Column field="email" header="Email" sortable style={{ width: '35%' }} />
                  <Column field="detach" header="Detach" style={{ width: '35%' }} body={detachBody} />
                </DataTable>
              </div>
            </div>

            <div className="col-12 xl:col-6">
              <div className="card">
                <div className="flex justify-content-between align-items-center mb-5">
                  <h5>Daily Averages</h5>
                </div>
                <DataTable
                  value={deviceLogs}
                  scrollable
                  scrollHeight="350px"
                  loading={loading}
                  scrollDirection="both"
                  className="mt-3"
                >
                  <Column
                    field="date"
                    header="Date"
                    style={{ flexGrow: 1, flexBasis: '160px' }}
                    body={dateBodyTemplate}
                    frozen
                  ></Column>
                  <Column field="ph" header="PH" style={{ flexGrow: 1, flexBasis: '120px' }}></Column>
                  <Column field="orp" header="ORP" style={{ flexGrow: 1, flexBasis: '120px' }}></Column>
                  <Column field="salinity" header="SALINITY" style={{ flexGrow: 1, flexBasis: '120px' }}></Column>
                  <Column field="vsdw" header="POWER" style={{ flexGrow: 1, flexBasis: '120px' }}></Column>
                </DataTable>
              </div>
            </div>
          </div>
          <DeviceParams shadow={deviceShadow} device={device} dispatchToParent={editedDeviceParam} />
        </div>
        <OverlayPanel
          ref={op}
          showCloseIcon
          id="overlay_panel"
          style={{ width: '450px' }}
          className="overlaypanel-demo"
        >
          <div className="card">
            <form onSubmit={handleSubmit(onAttachUser)} className="p-fluid">
              <div className="field">
                <span className="p-float-label p-input-icon-right">
                  <i className="pi pi-envelope" />
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: 'Email is required.',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Invalid email address. E.g. example@email.com',
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                  <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>
                    Email*
                  </label>
                </span>
                {getFormErrorMessage('email')}
              </div>
              <Button label="Attach" className="p-button-warning" loading={buttonLoading} />
            </form>
          </div>
        </OverlayPanel>
        {/* Update Dialogue box */}
        <Dialog
          visible={showMessage}
          onHide={() => setShowMessage(false)}
          position="top"
          footer={dialogFooter}
          showHeader={false}
          breakpoints={{ '960px': '80vw' }}
          style={{ width: '30vw' }}
        >
          <div className="flex justify-content-center flex-column pt-6 px-3">
            <i className="pi pi-question-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
            <h5>Confirm Detach!</h5>
            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>{message}</p>
          </div>
        </Dialog>

        {/* Update firmware overlay */}
        <OverlayPanel
          ref={fwOp}
          showCloseIcon
          id="overlay_panel"
          style={{ width: '700px' }}
          className="overlaypanel-demo"
        >
          <div className="card">
            <div
              className="flex"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>
                <b>
                  Current {selectedEnv} Firmware version: {fwVersions ? fwVersions[0] : ''}
                </b>
              </div>
              <Divider layout="vertical" />
              <div>
                <b>
                  Current {selectedEnv} Nipper version:{nipperVersions ? nipperVersions[0] : ''}
                </b>
              </div>
            </div>
            <div
              className="flex"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>
                <RadioButton
                  inputId="fwType1"
                  name="fwType"
                  value={PRODUCT.LIFEGUARD}
                  onChange={(e) => setSelectedType(e.value)}
                  checked={selectedType === PRODUCT.LIFEGUARD}
                />
              </div>
              <div>
                <Dropdown
                  value={selectedFw}
                  options={fwVersions}
                  onChange={onFWChange}
                  placeholder="Select a firmware version"
                />
              </div>
              <Divider layout="vertical" />
              <div>
                <RadioButton
                  inputId="fwType2"
                  name="fwType"
                  value={PRODUCT.NIPPER}
                  onChange={(e) => setSelectedType(e.value)}
                  checked={selectedType === PRODUCT.NIPPER}
                />
              </div>
              <div>
                <Dropdown
                  value={selectedNipper}
                  options={nipperVersions}
                  onChange={onNipperChange}
                  placeholder="Select a nipper version"
                />
              </div>
              <Divider layout="vertical" />
              <div>
                <Dropdown
                  value={selectedEnv}
                  options={envs}
                  onChange={onEnvChange}
                  placeholder="Select a release version"
                />
              </div>
            </div>
            <br />
            <br />
            <div
              className="flex"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button label="UPDATE" onClick={onSubmitFW} loading={loading} />
            </div>
          </div>
        </OverlayPanel>
        {/* Update Dialogue box */}
        <Dialog
          visible={showFwMessage}
          onHide={() => setShowFwMessage(false)}
          position="top"
          footer={dialogFwFooter}
          showHeader={false}
          breakpoints={{ '960px': '80vw' }}
          style={{ width: '30vw' }}
        >
          <div className="flex justify-content-center flex-column pt-6 px-3">
            <i className="pi pi-question-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
            <h5>Confirm Update!</h5>
            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>{fwMessage}</p>
          </div>
        </Dialog>

        {/* Error Dialogue box */}
        <Dialog
          visible={errorFwMessage}
          onHide={() => setErrorFwMessage(false)}
          position="top"
          footer={errorDialogFwFooter}
          showHeader={false}
          breakpoints={{ '960px': '80vw' }}
          style={{ width: '30vw' }}
        >
          <div className="flex justify-content-center flex-column pt-6 px-3">
            <i className="pi pi-info-circle" style={{ fontSize: '5rem', color: 'red' }}></i>
            <h5>Error!</h5>
            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>{fwMessage}</p>
          </div>
        </Dialog>

        {/* History flex box */}
        <Dialog
          header="Device History"
          visible={dialogVisible}
          style={{ width: '75vw' }}
          maximizable
          modal
          contentStyle={{ height: '50vw' }}
          onHide={closeDialog}
        >
          <Messages ref={msg} />
          <DataTable
            value={deviceHistory}
            scrollable
            scrollHeight="flex"
            loading={loadingHistory}
            paginator
            rows={10}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rowsPerPageOptions={[10, 25, 50]}
            rowHover
            globalFilter={globalFilter}
            header={header}
          >
            <Column
              field="eventType"
              header="Event type"
              style={{ minWidth: '200px' }}
              sortable
              filter
              filterPlaceholder="Filter by event type"
            ></Column>
            <Column field="event.request" header="Event" style={{ minWidth: '200px' }}></Column>
            <Column
              field="changedFrom"
              header="Updated from"
              style={{ minWidth: '200px' }}
              sortable
              filter
              filterPlaceholder="Filter by updated from"
            ></Column>
            <Column
              field="updatedAt"
              header="Updated on"
              style={{ minWidth: '200px' }}
              body={dateTimeBodyTemplate}
              sortable
            ></Column>
          </DataTable>
        </Dialog>
        {/* Alarms flex box */}
        <Dialog
          header="Recent Alarms"
          visible={alarmDialogVisible}
          style={{ width: '75vw' }}
          maximizable
          modal
          contentStyle={{ height: '50vw' }}
          onHide={closeDialog}
        >
          <Messages ref={msg} />
          <DataTable
            value={alarms}
            scrollable
            scrollHeight="flex"
            loading={loadingHistory}
            paginator
            filters={alarmFilters}
            onFilter={(e) => setAlarmFilters(e.filters)}
            rows={10}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50]}
            rowHover
            emptyMessage="No Alarms found."
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            globalFilter={globalFilter}
            header={header}
          >
            <Column
              field="createdAt"
              header="Created Date"
              style={{ flexGrow: 1, flexBasis: '160px' }}
              body={alarmDateBodyTemplate}
              sortable
              filter
              filterPlaceholder="Filter by created date"
              frozen
            ></Column>
            <Column
              field="type"
              header="Alarm type"
              style={{ flexGrow: 1, flexBasis: '120px' }}
              sortable
              filter
              filterPlaceholder="Filter by alarm type"
            ></Column>
            <Column
              field="errorId"
              header="Error id"
              style={{ flexGrow: 1, flexBasis: '120px' }}
              sortable
              filter
              filterPlaceholder="Filter by error id"
            ></Column>
            <Column
              field="value"
              header="Value"
              style={{ flexGrow: 1, flexBasis: '120px' }}
              sortable
              filter
              filterPlaceholder="Filter by value"
            ></Column>
            <Column
              field="isRead"
              header="Status"
              dataType="boolean"
              body={alarmReadBodyTemplate}
              style={{ flexGrow: 1, flexBasis: '120px' }}
              sortable
              filter
              filterElement={connStatusFilterTemplate}
            ></Column>
          </DataTable>
        </Dialog>

        {/* Chart flex box */}
        <Dialog
          header={chartHeader}
          visible={chartDialogVisible}
          style={{ width: '75vw' }}
          maximizable
          modal
          contentStyle={{ height: '50vw' }}
          onHide={closeDialog}
        >
          <Messages ref={msg} />
          <DeviceLogsChart payload={{ serialNumber: device.serialNumber, data: { chartData } }} />
        </Dialog>
      </div>
    );
  }
};
