import { requestWithoutAuth } from '../request/axios.request';
import { appConfig, environment } from '../../Config';
import {
  LOGIN,
  LOGIN_DEV,
  REGISTER,
  REGISTER_DEV,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_DEV,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_DEV,
} from '../routes/route';
export const loginUserApi = ({ payload }) => {
  if (environment === 'dev') {
    return requestWithoutAuth({
      url: `${appConfig[environment]['backendURL-user']}${LOGIN_DEV}`,
      method: 'post',
      body: payload,
    });
  }
  return requestWithoutAuth({ url: `${appConfig[environment].backendURL}${LOGIN}`, method: 'post', body: payload });
};

export const registerUserApi = ({ payload }) => {
  if (environment === 'dev') {
    return requestWithoutAuth({
      url: `${appConfig[environment]['backendURL-user']}${REGISTER_DEV}`,
      method: 'post',
      body: payload,
    });
  }
  return requestWithoutAuth({ url: `${appConfig[environment].backendURL}${REGISTER}`, method: 'post', body: payload });
};

export const forgotPasswordApi = ({ payload }) => {
  if (environment === 'dev') {
    return requestWithoutAuth({
      url: `${appConfig[environment]['backendURL-user']}${FORGOT_PASSWORD_DEV}`,
      method: 'post',
      body: payload,
    });
  }
  return requestWithoutAuth({
    url: `${appConfig[environment].backendURL}${FORGOT_PASSWORD}`,
    method: 'post',
    body: payload,
  });
};

export const updatePasswordApi = ({ payload }) => {
  if (environment === 'dev') {
    return requestWithoutAuth({
      url: `${appConfig[environment]['backendURL-user']}${UPDATE_PASSWORD_DEV}`,
      method: 'post',
      body: payload,
    });
  }
  return requestWithoutAuth({
    url: `${appConfig[environment].backendURL}${UPDATE_PASSWORD}`,
    method: 'post',
    body: payload,
  });
};
