import { getToken } from '../../storage/tokens';

export const getHeaders = (method, payload) => {
  let headers = getToken();
  headers = headers.replace(/"/g, '');
  const options: any = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: headers,
    },
  };
  if (method !== 'get') {
    options.body = JSON.stringify(payload);
  }

  return options;
};

export const getHeadersWithoutAuth = (method, payload) => {
  const options: any = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (method !== 'get') {
    options.body = JSON.stringify(payload);
  }

  return options;
};
