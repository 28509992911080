import jwt_decode from 'jwt-decode';

export function getToken() {
  const token = localStorage.getItem('ACCESS_TOKEN');
  return token;
}

export function setToken(token, refreshToken) {
  localStorage.removeItem('ACCESS_TOKEN');
  localStorage.setItem('ACCESS_TOKEN', token);
  localStorage.removeItem('REFRESH_TOKEN');
  localStorage.setItem('REFRESH_TOKEN', refreshToken);
  return token;
}

export function removeLocalData() {
  localStorage.removeItem('ACCESS_TOKEN');
  localStorage.removeItem('REFRESH_TOKEN');
  return true;
}

export function isValidToken() {
  const token = localStorage.getItem('ACCESS_TOKEN');
  //if (!appConfig[environment].isBackend && token) return true;
  console.log('valid token: ');
  if (!token) return false;
  const date = new Date().getTime() / 1000;
  const data: any = jwt_decode(token);
  if (!data) {
    return false;
  }
  console.log('is valid  ?? : ', date < data.exp);
  return date < data.exp;
}
