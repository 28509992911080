import { request } from '../request/axios.request';
import { appConfig, environment } from '../../Config';
import { ALL_USERS, ALL_USERS_DEV } from '../routes/route';

export const enableMockData = () => {
  if (environment === 'dev') {
    return true;
  } else {
    return false;
  }
};

export const getAllUsers = () => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-user']}${ALL_USERS_DEV}`,
      method: 'get',
      body: null,
    });
  }
  return request({ url: `${appConfig[environment].backendURL}${ALL_USERS}`, method: 'get', body: null });
};
