import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from './Login.action';
import { DEFAULT_STATE } from './Login.state';

const INITIAL_STATE = DEFAULT_STATE;

const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        auth: action.data,
        error: {},
        loading: false,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        auth: {},
        error: {},
        loading: false,
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_REQUEST:
      return {
        ...state,
        registration: {
          status: 'requested',
          message: 'Requested',
        },
        loading: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registration: {
          status: 'success',
          code: action?.data?.code,
          message: action?.data?.message || 'Registration successful',
        },
        loading: false,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        registration: {
          status: 'failed',
          code: action?.data?.error?.code || '',
          message: action?.data?.error?.message || 'Registration failed',
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default AuthReducer;
