import React from 'react';

interface AppFooterProps {
  layoutColorMode: string;
}

export const AppFooter = (props: AppFooterProps) => {
  return (
    <div className="layout-footer">
      {/* <img
        src={
          props.layoutColorMode === 'light'
            ? 'assets/layout/images/davey_logo1.png'
            : 'assets/layout/images/davey_logo.png'
        }
        alt="Logo"
        height="20"
        className="mr-2"
      /> */}

      <span className="font-medium ml-2">© {new Date().getFullYear()} Davey Water | All rights reserved.</span>
    </div>
  );
};
