import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import { CSSTransition } from 'react-transition-group';
import { useForm, Controller } from 'react-hook-form';
import { Messages } from 'primereact/messages';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import queryString from 'query-string';
import PrimeReact from 'primereact/api';

import './ForgotPassword.scss';

import { AppTopbar } from '../../../AppTopbar';
import { AppFooter } from '../../../AppFooter';
import { AppConfig } from '../../../AppConfig';
import { updatePasswordApi } from '../../../api/service/LoginService';

export const ResetPassword = (props) => {
  const [layoutMode, setLayoutMode] = useState<string>('static');
  const [layoutColorMode, setLayoutColorMode] = useState<string>('dark');
  const [inputStyle, setInputStyle] = useState<string>('outlined');
  const [ripple, setRipple] = useState<boolean>(true);
  const [overlayMenuActive, setOverlayMenuActive] = useState<boolean>(false);
  const [mobileMenuActive, setMobileMenuActive] = useState<boolean>(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const reRef = useRef<ReCAPTCHA>();
  const staticMenuInactive = true;
  PrimeReact.ripple = true;
  const msg = useRef(null);
  let menuClick = false;
  let mobileTopbarMenuClick = false;
  const queryParams = queryString.parse(window.location.search);
  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, 'body-overflow-hidden');
    } else {
      removeClass(document.body, 'body-overflow-hidden');
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    if (error?.message) {
      console.log('login error: ', error);
      setLoading(false);
      msg.current.show({
        severity: 'error',
        sticky: true,
        content: <React.Fragment>{error.message}</React.Fragment>,
      });
    }
  }, [error]);

  useEffect(() => {
    console.log('queryParams error: ', queryParams);
    if (!queryParams?.ofl || !queryParams?.email) {
      msg.current.show({
        severity: 'error',
        sticky: true,
        content: <React.Fragment>"Invalid URL. Please contact admin"</React.Fragment>,
      });
      setDisabled(true);
    }
  }, [queryParams]);

  const onInputStyleChange = (inputStyle: string) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e: any) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode: string) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode: string) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onMobileTopbarMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const addClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.add(className);
    else element.className += ' ' + className;
  };

  const removeClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' ',
      );
  };

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': ripple === false,
    'layout-theme-light': layoutColorMode === 'light',
  });

  const [showMessage, setShowMessage] = useState(false);

  const defaultValues = {
    password: '',
    cPassword: '',
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  const onSubmit = async (data: { password: string; cPassword: string }) => {
    console.log('submit form: ', data);
    msg.current.clear();
    if (data.password !== data.cPassword) {
      msg.current.show({
        severity: 'error',
        sticky: true,
        content: <React.Fragment>Passwords not matching</React.Fragment>,
      });
    } else if (!queryParams.email || !queryParams.ofl) {
      msg.current.show({
        severity: 'error',
        sticky: true,
        content: <React.Fragment>Invalid URL. Please contact admin</React.Fragment>,
      });
    } else {
      setLoading(true);
      const token = await reRef.current.executeAsync();
      reRef.current.reset();
      updatePasswordApi({
        payload: { password: data.password, email: queryParams.email, ofl: queryParams.ofl, token },
      }).then((response) => {
        setLoading(false);
        console.log('updatePasswordApi resp: ', response);
        if (response?.error?.message) {
          setError({ message: response?.error?.message });
        } else {
          setShowMessage(true);
        }
      });
    }
  };

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };
  const onSuccess = () => {
    setShowMessage(false);
    props.history.push('/login');
  };

  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button label="OK" className="p-button-warning" autoFocus onClick={onSuccess} />
    </div>
  );
  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <AppTopbar
        piBarDisable={true}
        onToggleMenuClick={null}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div className="layout-main-container">
        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} size="invisible" ref={reRef} />
        <div className="layout-main">
          <div className="form-look">
            <Dialog
              visible={showMessage}
              onHide={() => setShowMessage(false)}
              position="top"
              footer={dialogFooter}
              showHeader={false}
              breakpoints={{ '960px': '80vw' }}
              style={{ width: '30vw' }}
            >
              <div className="flex justify-content-center flex-column pt-6 px-3">
                <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                <h5>Password reset successful</h5>
                <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>Please login with new password</p>
              </div>
            </Dialog>
            <div className="flex justify-content-center">
              <div className="card">
                <h5 className="text-center">Reset Password</h5>
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                  <Messages ref={msg} />
                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name="password"
                        control={control}
                        rules={{ required: 'Password is required.' }}
                        render={({ field, fieldState }) => (
                          <Password
                            id={field.name}
                            {...field}
                            feedback={false}
                            toggleMask
                            className={classNames({ 'p-invalid': fieldState.invalid })}
                          />
                        )}
                      />
                      <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>
                        Password*
                      </label>
                    </span>
                    {getFormErrorMessage('password')}
                  </div>
                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name="cPassword"
                        control={control}
                        rules={{ required: 'retype Password is required.' }}
                        render={({ field, fieldState }) => (
                          <Password
                            id={field.name}
                            {...field}
                            feedback={false}
                            toggleMask
                            className={classNames({ 'p-invalid': fieldState.invalid })}
                          />
                        )}
                      />
                      <label htmlFor="cPassword" className={classNames({ 'p-error': errors.cPassword })}>
                        Retype Password*
                      </label>
                    </span>
                    {getFormErrorMessage('cPassword')}
                  </div>
                  <Button type="submit" label="Submit" className="mt-2" loading={loading} disabled={disabled} />
                </form>
              </div>
            </div>
          </div>
        </div>

        <AppFooter layoutColorMode={layoutColorMode} />
      </div>

      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        onColorModeChange={onColorModeChange}
      />

      <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  );
};
