import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useForm, Controller } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';

import { AppTopbar } from '../../../AppTopbar';
import { AppFooter } from '../../../AppFooter';
import { AppConfig } from '../../../AppConfig';
import { registerUser } from '../../../containers/login/Login.action';
import { CountryService } from '../../../api/service/CountryService';

import PrimeReact from 'primereact/api';
import './Register.scss';

export const Register = (props) => {
  const [layoutMode, setLayoutMode] = useState<string>('static');
  const [layoutColorMode, setLayoutColorMode] = useState<string>('dark');
  const [inputStyle, setInputStyle] = useState<string>('outlined');
  const [ripple, setRipple] = useState<boolean>(true);
  const [overlayMenuActive, setOverlayMenuActive] = useState<boolean>(false);
  const [mobileMenuActive, setMobileMenuActive] = useState<boolean>(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState<boolean>(false);
  const staticMenuInactive = true;
  const reRef = useRef<ReCAPTCHA>();
  const dispatch = useDispatch();
  const registrationRes = useSelector((state: any) => state.auth.registration);

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, 'body-overflow-hidden');
    } else {
      removeClass(document.body, 'body-overflow-hidden');
    }
  }, [mobileMenuActive]);

  const onInputStyleChange = (inputStyle: string) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e: any) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode: string) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode: string) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onMobileTopbarMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const addClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.add(className);
    else element.className += ' ' + className;
  };

  const removeClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' ',
      );
  };

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': ripple === false,
    'layout-theme-light': layoutColorMode === 'light',
  });

  const [countries, setCountries] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [showErrorMessage, setErrorShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    country: null,
    accept: false,
  });
  const countryservice = new CountryService();
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    country: null,
    accept: false,
  };

  useEffect(() => {
    countryservice.getCountries().then((data) => setCountries(data));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (registrationRes?.status === 'failed') {
      setErrorShowMessage(true);
      setLoading(false);
    } else if (registrationRes?.status === 'success') {
      setShowMessage(true);
      setLoading(false);
    }
  }, [registrationRes]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = async (data) => {
    console.log('submit form: ', data);
    setLoading(true);
    setFormData(data);
    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    data.country = data.country.name;
    data.token = token;
    dispatch(registerUser(data));
  };

  const onSuccess = () => {
    setShowMessage(false);
    reset();
    props.history.push('/login');
  };

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button label="OK" className="p-button-warning" autoFocus onClick={onSuccess} />
    </div>
  );
  const dialogErrorFooter = (
    <div className="flex justify-content-center">
      <Button label="OK" className="p-button-warning" autoFocus onClick={() => setErrorShowMessage(false)} />
    </div>
  );
  const passwordHeader = <h6>Pick a password</h6>;
  const passwordFooter = (
    <React.Fragment>
      <Divider />
      <p className="mt-2">Suggestions</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>Minimum 8 characters</li>
      </ul>
    </React.Fragment>
  );
  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <AppTopbar
        piBarDisable={true}
        onToggleMenuClick={null}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div className="layout-main-container">
        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} size="invisible" ref={reRef} />
        <div className="layout-main">
          <div className="form-look">
            <Dialog
              visible={showMessage}
              onHide={() => setShowMessage(false)}
              position="top"
              footer={dialogFooter}
              showHeader={false}
              breakpoints={{ '960px': '80vw' }}
              style={{ width: '30vw' }}
            >
              <div className="flex justify-content-center flex-column pt-6 px-3">
                <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                <h5>Registration Successful!</h5>
                <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                  Your account is registered under name{' '}
                  <b>
                    {formData.firstName} {formData.lastName}
                  </b>{' '}
                  ; Please check <b>{formData.email}</b> for activation instructions.
                </p>
              </div>
            </Dialog>
            <Dialog
              visible={showErrorMessage}
              onHide={() => setErrorShowMessage(false)}
              position="top"
              footer={dialogErrorFooter}
              showHeader={false}
              breakpoints={{ '960px': '80vw' }}
              style={{ width: '30vw' }}
            >
              <div className="flex justify-content-center flex-column pt-6 px-3">
                <i className="pi pi-info-circle" style={{ fontSize: '5rem', color: 'red' }}></i>
                <h5>Registration Failed!</h5>
                <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                  Your registration failed due to following error : {registrationRes?.message}
                </p>
              </div>
            </Dialog>
            <div className="flex justify-content-center">
              <div className="card">
                <h5 className="text-center">Register</h5>
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name="firstName"
                        control={control}
                        rules={{ required: 'Name is required.' }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            autoFocus
                            className={classNames({ 'p-invalid': fieldState.invalid })}
                          />
                        )}
                      />
                      <label htmlFor="firstName" className={classNames({ 'p-error': errors.firstName })}>
                        First Name*
                      </label>
                    </span>
                    {getFormErrorMessage('name')}
                  </div>
                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name="lastName"
                        control={control}
                        rules={{ required: 'Name is required.' }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            autoFocus
                            className={classNames({ 'p-invalid': fieldState.invalid })}
                          />
                        )}
                      />
                      <label htmlFor="lastName" className={classNames({ 'p-error': errors.lastName })}>
                        Last Name*
                      </label>
                    </span>
                    {getFormErrorMessage('name')}
                  </div>
                  <div className="field">
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-envelope" />
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: 'Email is required.',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Invalid email address. E.g. example@email.com',
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            className={classNames({ 'p-invalid': fieldState.invalid })}
                          />
                        )}
                      />
                      <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>
                        Email*
                      </label>
                    </span>
                    {getFormErrorMessage('email')}
                  </div>
                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name="password"
                        control={control}
                        rules={{ required: 'Password is required.' }}
                        render={({ field, fieldState }) => (
                          <Password
                            id={field.name}
                            {...field}
                            toggleMask
                            className={classNames({ 'p-invalid': fieldState.invalid })}
                            header={passwordHeader}
                            footer={passwordFooter}
                          />
                        )}
                      />
                      <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>
                        Password*
                      </label>
                    </span>
                    {getFormErrorMessage('password')}
                  </div>
                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name="country"
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            value={field.value}
                            onChange={(e) => field.onChange(e.value)}
                            options={countries}
                            optionLabel="name"
                            filter
                            showClear
                            filterBy="name"
                          />
                        )}
                      />
                      <label htmlFor="country">Country</label>
                    </span>
                  </div>
                  <div className="field-checkbox">
                    <Controller
                      name="accept"
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={(e) => field.onChange(e.checked)}
                          checked={field.value}
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                    <label htmlFor="accept" className={classNames({ 'p-error': errors.accept })}>
                      I agree to the terms and conditions*
                    </label>
                  </div>

                  <Button type="submit" label="Submit" className="mt-2" loading={loading} />
                </form>
                <div>
                  <span className="login">
                    Already have an account? <a href="/login">Log in</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AppFooter layoutColorMode={layoutColorMode} />
      </div>

      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        onColorModeChange={onColorModeChange}
      />

      <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  );
};
