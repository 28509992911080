import { put, takeEvery, call } from 'redux-saga/effects';
import {
  authenticateResponse,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  logoutResponse,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  registerUserResponse,
} from './Login.action';
import { loginUserApi, registerUserApi } from '../../api/service/LoginService';
import { requestLogin, requestLogout, registerRequest } from './Login.action';
import { forwardTo } from './Login.helper';

import { setToken, removeLocalData } from '../../storage/tokens';

function* authenticateUserReq(data) {
  yield requestLogin();
  yield authenticateUser(data);
}

function* authenticateUser(data) {
  try {
    const ValidateCredential = yield loginUserApi(data);
    console.log('ValidateCredential :', ValidateCredential);
    if (ValidateCredential.error) {
      console.log('Error while loging in:', ValidateCredential.error);
      yield put(authenticateResponse(LOGIN_FAILURE, ValidateCredential.error));
    } else {
      setToken(JSON.stringify(ValidateCredential.data.token), JSON.stringify(ValidateCredential.data.refreshToken));
      yield put(authenticateResponse(LOGIN_SUCCESS, ValidateCredential.data));
      yield call(forwardTo, '/dashboard');
    }
  } catch (error) {
    yield put(authenticateResponse(LOGIN_FAILURE, ''));
  }
}

function* logoutUserReq(data) {
  console.log('authenticateUserReq: ', data);
  yield requestLogout();
  yield logoutUser();
}

function* logoutUser() {
  try {
    console.log('logoutUser');
    removeLocalData();
    yield put(logoutResponse(LOGOUT_SUCCESS));
    yield call(forwardTo, '/login');
  } catch (error) {
    yield put(logoutResponse(LOGOUT_FAILURE));
  }
}

function* registerUserReq(data) {
  yield registerRequest();
  yield registerUser(data);
}

function* registerUser(data) {
  try {
    const res = yield registerUserApi(data);
    console.log('registerUser call :', res);
    if (res.error && res.error.message) {
      yield put(registerUserResponse(REGISTER_FAILURE, res));
    } else {
      yield put(registerUserResponse(REGISTER_SUCCESS, res));
    }
    //yield call(forwardTo, '/dashboard');
  } catch (error) {
    yield put(registerUserResponse(REGISTER_FAILURE, ''));
  }
}

export function* authenticateUserWatcher() {
  yield takeEvery(LOGIN_REQUEST, authenticateUserReq);
}

export function* logoutUserWatcher() {
  yield takeEvery(LOGOUT_REQUEST, logoutUserReq);
}

export function* registerUserWatcher() {
  yield takeEvery(REGISTER_REQUEST, registerUserReq);
}
