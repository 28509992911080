import React, { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';

import { editSchedulesAPI, changeModeAPI, changeTargetsAPI, updateParamsAPI } from '../../../api/service/DeviceService';
import ConfirmDialogBox from '../repetitiveComp/ConfirmDialogBox';
import {
  changeModePayload,
  changeTargetsPayload,
  editSchedulesPayload,
  updateParamsPayload,
} from '../../../api/service/service.types';

import './EditingForm.scss';
import '../../../App.scss';

const EditingForm = ({ name, shadow, device }) => {
  console.log('Editing Form : ', name, shadow, device);
  const formattedShadow = shadow.formattedShadow;
  const sch1 = formattedShadow?.schedules[0];
  const sch2 = formattedShadow?.schedules[1];
  const vsd = formattedShadow.setVsd.split(',');
  const modesValue = formattedShadow.modes.map((x) => {
    return { name: x };
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [schFormData, setSchFormData] = useState({
    t1On: sch1.on,
    t1Off: sch1.off,
    t1Days: sch1.days === '1111111' ? 'Every day' : sch1.days,
    t1Speed: sch1.speed,
    t2On: sch2.on,
    t2Off: sch2.off,
    t2Days: sch2.days === '1111111' ? 'Every day' : sch2.days,
    t2Speed: sch2.speed,
  });
  const [selectedModes, setSelectedModes] = useState<any>(modesValue);
  const [targetsFormData, setTargetsFormData] = useState({
    ph: formattedShadow.phSetpoint,
    orp: formattedShadow.orpSetpoint,
    temp: formattedShadow.tempSetpoint,
    salinity: formattedShadow.salinitySetpoint,
    vsdPumpSpeed: Number(vsd[1]),
    cell: formattedShadow.setCellOutput,
    isVsdPumpConnected: vsd[0] ? true : false,
    phConnected: formattedShadow.phConnected,
    orpConnected: formattedShadow.orpConnected,
    salinityConnected: formattedShadow.salinityConnected,
    tempConnected: formattedShadow.tempConnected,
    boostDuration: formattedShadow.setBoostDuration,
    backwashDuration: formattedShadow.setBackwashDuration,
  });
  const [paramsData, setParamsData] = useState({
    acidPump: device.acidPump,
    heatPump: device.heatPump,
  });
  const msg = useRef(null);

  const defaultValues = {
    modes: [],
    t1On: sch1.on,
    t1Off: sch1.off,
    t1Days: sch1.days === '1111111' ? 'Every day' : sch1.days,
    t1Speed: sch1.speed,
    t2On: sch2.on,
    t2Off: sch2.off,
    t2Days: sch2.days === '1111111' ? 'Every day' : sch2.days,
    t2Speed: sch2.speed,
    ph: formattedShadow.phSetpoint,
    orp: formattedShadow.orpSetpoint,
    temp: formattedShadow.tempSetpoint,
    salinity: formattedShadow.salinitySetpoint,
    vsdPumpSpeed: Number(vsd[1]),
    cell: formattedShadow.setCellOutput,
    isVsdPumpConnected: vsd[0] ? true : false,
    phConnected: formattedShadow.phConnected,
    orpConnected: formattedShadow.orpConnected,
    salinityConnected: formattedShadow.salinityConnected,
    tempConnected: formattedShadow.tempConnected,
    boostDuration: formattedShadow.setBoostDuration,
    backwashDuration: formattedShadow.setBackwashDuration,
    acidPump: device.acidPump,
    heatPump: device.heatPump,
  };

  const ModeTypes = [
    { name: 'MANUAL' },
    { name: 'BOOST' },
    { name: 'SCHEDULE' },
    { name: 'SPA' },
    { name: 'COVER' },
    { name: 'WINTER' },
    { name: 'MAINTENANCE' },
    { name: 'SERVICE' },
    { name: 'BACKWASH' },
  ];

  const disabledModes = ['SERVICE', 'MAINTENANCE', 'BACKWASH'];

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });
  const onSchUpdate = (data) => {
    console.log('Sch data', data);
    if (data.t1On > data.t1Off || data.t2On > data.t2Off) {
      console.log('error in timer. On time should be lesser than Off time');
      msg.current.clear();
      msg.current.show({
        severity: 'error',
        sticky: true,
        content: <React.Fragment>"error in timer. On-time should be lesser than Off-time"</React.Fragment>,
      });
    } else {
      setSchFormData(data);
      setShowConfirmMessage(true);
      setConfirmMessage('Are you sure you want to update schedules?');
      //setMessage('Schedules updated successfully');
    }
  };
  const onConfirmScheduleUpdate = () => {
    setShowConfirmMessage(false);
    const payload: editSchedulesPayload = {
      serialNumber: String(shadow.id),
      firmwareVersion: formattedShadow.firmwareVersion,
      schedules: [
        {
          on: new Date('1970-01-01T' + schFormData.t1On + 'Z').getTime() / 1000,
          off: new Date('1970-01-01T' + schFormData.t1Off + 'Z').getTime() / 1000,
          days: String(sch1.days),
          speed: Number(schFormData.t1Speed),
        },
        {
          on: new Date('1970-01-01T' + schFormData.t2On + 'Z').getTime() / 1000,
          off: new Date('1970-01-01T' + schFormData.t2Off + 'Z').getTime() / 1000,
          days: String(sch2.days),
          speed: Number(schFormData.t2Speed),
        },
      ],
    };
    console.log('onConfirmUpdate clicked', schFormData, payload);
    msg.current.clear();
    setButtonLoading(true);
    editSchedulesAPI(payload).then((res) => {
      console.log('editSchedulesAPI resp: ', res);
      if (!res.success && res?.error?.message) {
        msg.current.show({
          severity: 'error',
          sticky: true,
          content: <React.Fragment>{res?.error?.message}</React.Fragment>,
        });
      } else {
        msg.current.show({
          severity: 'success',
          sticky: true,
          content: <React.Fragment>Schedules updated successfully. Refresh page after 30s</React.Fragment>,
        });
      }
      setButtonLoading(false);
    });
  };
  const onModesUpdate = () => {
    console.log('Modes data', selectedModes);
    setShowConfirmMessage(true);
    setConfirmMessage('Are you sure you want to update selected modes?');
  };

  const onConfirmModesUpdate = () => {
    const modesValue = selectedModes.map((x) => {
      return x.name;
    });
    console.log('onConfirmModesUpdate clicked', selectedModes);
    setShowConfirmMessage(false);
    const payload: changeModePayload = {
      serialNumber: String(shadow.id),
      modes: modesValue,
    };
    msg.current.clear();
    setButtonLoading(true);
    changeModeAPI(payload).then((res) => {
      console.log('changeModeAPI resp: ', res);
      if (!res.success && res?.error?.message) {
        msg.current.show({
          severity: 'error',
          sticky: true,
          content: <React.Fragment>{res?.error?.message}</React.Fragment>,
        });
      } else {
        msg.current.show({
          severity: 'success',
          sticky: true,
          content: <React.Fragment>Modes updated successfully</React.Fragment>,
        });
      }
      setButtonLoading(false);
    });
  };

  const onTargetsUpdate = (data) => {
    setTargetsFormData(data);
    console.log('Targets data', data);
    setShowConfirmMessage(true);
    setConfirmMessage('Are you sure you want to update Targets?');
  };

  const onConfirmTargetsUpdate = () => {
    console.log('onConfirmTargetsUpdate clicked', targetsFormData);
    setShowConfirmMessage(false);
    const payload: changeTargetsPayload = {
      serialNumber: String(shadow.id),
      targets: {
        ph: Number(targetsFormData.ph),
        orp: Number(targetsFormData.orp),
        temp: Number(targetsFormData.temp),
        salinity: Number(targetsFormData.salinity),
        vsdPumpSpeed: Number(targetsFormData.vsdPumpSpeed),
        cell: Number(targetsFormData.cell),
        isVsdPumpConnected: targetsFormData.isVsdPumpConnected,
        phConnected: targetsFormData.phConnected,
        orpConnected: targetsFormData.orpConnected,
        salinityConnected: targetsFormData.salinityConnected,
        tempConnected: targetsFormData.tempConnected,
        boostDuration: Number(targetsFormData.boostDuration),
        backwashDuration: Number(targetsFormData.backwashDuration),
      },
    };
    msg.current.clear();
    setButtonLoading(true);
    console.log('changeTargetsAPI payload: ', payload);
    changeTargetsAPI(payload).then((res) => {
      console.log('changeTargetsAPI resp: ', res);
      if (!res.success && res?.error?.message) {
        msg.current.show({
          severity: 'error',
          sticky: true,
          content: <React.Fragment>{res?.error?.message}</React.Fragment>,
        });
      } else {
        msg.current.show({
          severity: 'success',
          sticky: true,
          content: <React.Fragment>Targets updated successfully. Refresh page after 30s</React.Fragment>,
        });
      }
      setButtonLoading(false);
    });
  };

  const onParamsUpdate = (data) => {
    setParamsData(data);
    console.log('Params data', data);
    setShowConfirmMessage(true);
    setConfirmMessage('Are you sure you want to update Parameters?');
  };

  const onConfirmParamsUpdate = () => {
    console.log('onConfirmParamsUpdate clicked', paramsData);
    setShowConfirmMessage(false);
    const payload: updateParamsPayload = {
      serialNumber: String(shadow.id),
      parameters: {
        acidPump: paramsData.acidPump,
        heatPump: paramsData.heatPump,
      },
    };
    msg.current.clear();
    setButtonLoading(true);
    console.log('updateParamsAPI payload: ', payload);
    updateParamsAPI(payload).then((res) => {
      console.log('updateParamsAPI resp: ', res);
      if (!res.success && res?.error?.message) {
        msg.current.show({
          severity: 'error',
          sticky: true,
          content: <React.Fragment>{res?.error?.message}</React.Fragment>,
        });
      } else {
        msg.current.show({
          severity: 'success',
          sticky: true,
          content: <React.Fragment>Parameters updated successfully. Refresh page after 30s</React.Fragment>,
        });
      }
      setButtonLoading(false);
    });
  };

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>;
  };
  const cancelRequest = () => {
    setShowConfirmMessage(false);
  };

  const onCategoryChange = (e: { value: any; checked: boolean }) => {
    let _selectedModes = [...selectedModes];

    if (e.checked) {
      _selectedModes.push(e.value);
    } else {
      for (let i = 0; i < _selectedModes.length; i++) {
        const selectedCategory = _selectedModes[i];

        if (selectedCategory.name === e.value.name) {
          _selectedModes.splice(i, 1);
          break;
        }
      }
    }

    setSelectedModes(_selectedModes);
  };

  if (name === 'edit_schedules') {
    return (
      <div className="form-look">
        <ConfirmDialogBox
          visible={showConfirmMessage}
          onHide={() => setShowConfirmMessage(false)}
          showHeader={false}
          showMessage={confirmMessage}
          confirmMessage="Confirm Update"
          cancelRequest={cancelRequest}
          successRequest={onConfirmScheduleUpdate}
        />
        <div className="card">
          <Messages ref={msg} />
          <form onSubmit={handleSubmit(onSchUpdate)} className="p-fluid">
            <div className="p-fluid grid">
              <div className="field col-12 md:col-2">
                <span className="p-float-label">Timer 1:</span>
              </div>
              <div className="field col-12 md:col-2">
                <span className="p-float-label">
                  <Controller
                    name="t1On"
                    control={control}
                    rules={{ required: ' Timer 1 on time is required.' }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        type="time"
                        {...field}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                  <label htmlFor="t1On" className={classNames({ 'p-error': errors.t1On })}>
                    On*
                  </label>
                </span>
                {getFormErrorMessage('t1On')}
              </div>
              <div className="field col-12 md:col-2">
                <span className="p-float-label">
                  <Controller
                    name="t1Off"
                    control={control}
                    rules={{ required: ' Timer 1 off time is required.' }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        type="time"
                        {...field}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                  <label htmlFor="t1Off" className={classNames({ 'p-error': errors.t1Off })}>
                    Off*
                  </label>
                </span>
                {getFormErrorMessage('t1Off')}
              </div>

              <div className="field col-12 md:col-2">
                <span className="p-float-label">
                  <Controller
                    name="t1Speed"
                    control={control}
                    rules={{ required: ' Timer 1 speed time is required.', min: 2, max: 10 }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                  <label htmlFor="t1Speed" className={classNames({ 'p-error': errors.t1Speed })}>
                    Speed*
                  </label>
                </span>
                {getFormErrorMessage('t1Speed')}
                {errors.t1Speed && <p className="p-error">value should be between 2 - 10</p>}
              </div>
              <div className="field col-12 md:col-2">
                <span className="p-float-label">
                  <Controller
                    name="t1Days"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        disabled
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                  <label htmlFor="t1Days" className={classNames({ 'p-error': errors.t1Days })}>
                    Days (mon - sun)
                  </label>
                </span>
                {getFormErrorMessage('t1Days')}
              </div>
              <div className="field col-12 md:col-2">
                <span className="p-float-label">{/*  */}</span>
              </div>
              <div className="field col-12 md:col-2">
                <span className="p-float-label">Timer 2:</span>
              </div>
              <div className="field col-12 md:col-2">
                <span className="p-float-label">
                  <Controller
                    name="t2On"
                    control={control}
                    rules={{ required: ' Timer 2 On time is required.' }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        type="time"
                        {...field}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                  <label htmlFor="t2On" className={classNames({ 'p-error': errors.t2On })}>
                    On*
                  </label>
                </span>
                {getFormErrorMessage('t2On')}
              </div>
              <div className="field col-12 md:col-2">
                <span className="p-float-label">
                  <Controller
                    name="t2Off"
                    control={control}
                    rules={{ required: ' Timer 2 off time is required.' }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        type="time"
                        {...field}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                  <label htmlFor="t2Off" className={classNames({ 'p-error': errors.t2Off })}>
                    Off*
                  </label>
                </span>
                {getFormErrorMessage('t2Off')}
              </div>

              <div className="field col-12 md:col-2">
                <span className="p-float-label">
                  <Controller
                    name="t2Speed"
                    control={control}
                    rules={{ required: ' Timer 2 speed time is required.', min: 2, max: 10 }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                  <label htmlFor="t2Speed" className={classNames({ 'p-error': errors.t2Speed })}>
                    Speed*
                  </label>
                </span>
                {getFormErrorMessage('t2Speed')}
                {errors.t2Speed && <p className="p-error">value should be between 2 - 10</p>}
              </div>
              <div className="field col-12 md:col-2">
                <span className="p-float-label">
                  <Controller
                    name="t2Days"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        disabled
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                  <label htmlFor="t2Days" className={classNames({ 'p-error': errors.t2Days })}>
                    Days (mon - sun)
                  </label>
                </span>
                {getFormErrorMessage('t2Days')}
              </div>
            </div>

            <div className="field md:col-4 text-center">
              <Button label="UPDATE" className="p-button-raised p-button-warning" loading={buttonLoading} />
            </div>
          </form>
        </div>
      </div>
    );
  } else if (name === 'update_modes') {
    return (
      <div className="form-look">
        <ConfirmDialogBox
          visible={showConfirmMessage}
          onHide={() => setShowConfirmMessage(false)}
          showHeader={false}
          showMessage={confirmMessage}
          confirmMessage="Confirm Update"
          cancelRequest={cancelRequest}
          successRequest={onConfirmModesUpdate}
        />
        <div className="flex justify-content-center">
          <div className="card">
            <h5>Modes</h5>
            <div className="field">
              {ModeTypes.map((mode) => {
                return (
                  <div key={mode.name} className="field-checkbox">
                    <Checkbox
                      inputId={mode.name}
                      name="mode"
                      value={mode}
                      onChange={onCategoryChange}
                      checked={selectedModes.some((item) => item.name === mode.name)}
                      disabled={disabledModes.includes(mode.name)}
                    />
                    <label htmlFor={mode.name}>{mode.name}</label>
                  </div>
                );
              })}
            </div>
            <Messages ref={msg} />
            <div className="field">
              <Button
                label="UPDATE"
                className="p-button-raised p-button-warning"
                onClick={onModesUpdate}
                loading={buttonLoading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (name === 'targets') {
    return (
      <div className="form-look">
        <ConfirmDialogBox
          visible={showConfirmMessage}
          onHide={() => setShowConfirmMessage(false)}
          showHeader={false}
          showMessage={confirmMessage}
          confirmMessage="Confirm Update"
          cancelRequest={cancelRequest}
          successRequest={onConfirmTargetsUpdate}
        />

        <div className="card">
          {/* <div className="flex"> */}
          <div className="surface-0">
            <h5 className="text-center">Targets</h5>
            <form onSubmit={handleSubmit(onTargetsUpdate)} className="p-fluid">
              <div className="field col-12 md:col-8">
                <span className="p-float-label">
                  <Controller
                    name="ph"
                    control={control}
                    rules={{ required: ' PH setpoint is required.' }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        type="number"
                        {...field}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                  <label htmlFor="ph" className={classNames({ 'p-error': errors.ph })}>
                    <b>PH setpoint*</b>
                  </label>
                </span>
                {getFormErrorMessage('ph')}
              </div>
              <div className="field-checkbox">
                <label htmlFor="phConnected" className={classNames({ 'p-error': errors.phConnected })}>
                  <b>PH Connected?</b> &nbsp;&nbsp;
                </label>
                <Controller
                  name="phConnected"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputSwitch
                      inputId={field.name}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      className={classNames({ 'p-invalid': fieldState.invalid })}
                    />
                  )}
                />

                {getFormErrorMessage('phConnected')}
              </div>

              <div className="field col-12 md:col-8">
                <span className="p-float-label">
                  <Controller
                    name="orp"
                    control={control}
                    rules={{ required: ' ORP setpoint is required.' }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        type="number"
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                  <label htmlFor="orp" className={classNames({ 'p-error': errors.orp })}>
                    <b>ORP setpoint*</b>
                  </label>
                </span>
                {getFormErrorMessage('orp')}
              </div>
              <div className="field-checkbox">
                <label htmlFor="orpConnected" className={classNames({ 'p-error': errors.orpConnected })}>
                  <b>ORP Connected?</b> &nbsp;&nbsp;
                </label>
                <Controller
                  name="orpConnected"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputSwitch
                      inputId={field.name}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      className={classNames({ 'p-invalid': fieldState.invalid })}
                    />
                  )}
                />

                {getFormErrorMessage('orpConnected')}
              </div>
              <div className="field col-12 md:col-8">
                <span className="p-float-label">
                  <Controller
                    name="temp"
                    control={control}
                    rules={{ required: ' Temp setpoint is required.' }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        type="number"
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                  <label htmlFor="temp" className={classNames({ 'p-error': errors.temp })}>
                    <b>Temp setpoint*</b>
                  </label>
                </span>
                {getFormErrorMessage('temp')}
              </div>
              <div className="field-checkbox">
                <label htmlFor="tempConnected" className={classNames({ 'p-error': errors.tempConnected })}>
                  <b>Temp Connected?</b>&nbsp;&nbsp;
                </label>
                <Controller
                  name="tempConnected"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputSwitch
                      inputId={field.name}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      className={classNames({ 'p-invalid': fieldState.invalid })}
                    />
                  )}
                />

                {getFormErrorMessage('tempConnected')}
              </div>
              <div className="field col-12 md:col-8">
                <span className="p-float-label">
                  <Controller
                    name="salinity"
                    control={control}
                    rules={{ required: ' salinity setpoint is required.' }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        type="number"
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                  <label htmlFor="salinity" className={classNames({ 'p-error': errors.salinity })}>
                    <b>Salinity setpoint*</b>
                  </label>
                </span>
                {getFormErrorMessage('salinity')}
              </div>
              <div className="field-checkbox">
                <label htmlFor="salinityConnected" className={classNames({ 'p-error': errors.salinityConnected })}>
                  <b>Salinity Connected?</b> &nbsp;&nbsp;
                </label>
                <Controller
                  name="salinityConnected"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputSwitch
                      inputId={field.name}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      className={classNames({ 'p-invalid': fieldState.invalid })}
                    />
                  )}
                />

                {getFormErrorMessage('salinityConnected')}
              </div>

              <div className="field col-12 md:col-8">
                <span className="p-float-label">
                  <Controller
                    name="vsdPumpSpeed"
                    control={control}
                    rules={{ required: ' vsd pump speed is required.', min: 0, max: 10 }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        type="number"
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                  <label htmlFor="vsdPumpSpeed" className={classNames({ 'p-error': errors.vsdPumpSpeed })}>
                    <b>vsd pump speed*</b>
                  </label>
                </span>
                {getFormErrorMessage('vsdPumpSpeed')}
                {errors.vsdPumpSpeed && <p className="p-error">value should be between 0 - 10</p>}
              </div>
              <div className="field-checkbox">
                <label htmlFor="isVsdPumpConnected" className={classNames({ 'p-error': errors.isVsdPumpConnected })}>
                  <b>Vsd Pump Connected?</b> &nbsp;&nbsp;
                </label>
                <Controller
                  name="isVsdPumpConnected"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputSwitch
                      inputId={field.name}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      className={classNames({ 'p-invalid': fieldState.invalid })}
                    />
                  )}
                />

                {getFormErrorMessage('isVsdPumpConnected')}
              </div>

              <div className="field col-12 md:col-8">
                <span className="p-float-label">
                  <Controller
                    name="boostDuration"
                    control={control}
                    rules={{ required: ' Boost Duration is required.' }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        type="number"
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                  <label htmlFor="boostDuration" className={classNames({ 'p-error': errors.boostDuration })}>
                    <b>Boost Duration*</b>
                  </label>
                </span>
                {getFormErrorMessage('boostDuration')}
              </div>

              <div className="field col-12 md:col-8">
                <span className="p-float-label">
                  <Controller
                    name="backwashDuration"
                    control={control}
                    rules={{ required: ' Backwash Duration is required.' }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        type="number"
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                  <label htmlFor="backwashDuration" className={classNames({ 'p-error': errors.backwashDuration })}>
                    <b>Backwash Duration*</b>
                  </label>
                </span>
                {getFormErrorMessage('backwashDuration')}
              </div>
              <div className="field col-12 md:col-8">
                <span className="p-float-label">
                  <Controller
                    name="cell"
                    control={control}
                    rules={{ required: ' cell is required.' }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        type="number"
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                        disabled={targetsFormData.orpConnected === false}
                      />
                    )}
                  />
                  <label htmlFor="cell" className={classNames({ 'p-error': errors.cell })}>
                    <b>Cell output*</b>
                  </label>
                </span>
                {getFormErrorMessage('cell')}
              </div>

              <div className="field md:col-4 text-center">
                <Button label="UPDATE" className="p-button-raised p-button-warning" loading={buttonLoading} />
              </div>
              <Messages ref={msg} />
            </form>
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  } else if (name === 'params') {
    return (
      <div className="form-look">
        <ConfirmDialogBox
          visible={showConfirmMessage}
          onHide={() => setShowConfirmMessage(false)}
          showHeader={false}
          showMessage={confirmMessage}
          confirmMessage="Confirm Update"
          cancelRequest={cancelRequest}
          successRequest={onConfirmParamsUpdate}
        />

        <div className="card">
          <h5 className="text-center">Other Parameters</h5>
          <form onSubmit={handleSubmit(onParamsUpdate)} className="p-fluid">
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name="heatPump"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                  )}
                />
                <label htmlFor="ph" className={classNames({ 'p-error': errors.heatPump })}>
                  Heat pump ID
                </label>
              </span>
              {getFormErrorMessage('heatPump')}
            </div>
            <div className="field-checkbox">
              <label htmlFor="acidPump" className={classNames({ 'p-error': errors.acidPump })}>
                Acid pump connected? &nbsp;&nbsp;
              </label>
              <Controller
                name="acidPump"
                control={control}
                render={({ field, fieldState }) => (
                  <InputSwitch
                    inputId={field.name}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ 'p-invalid': fieldState.invalid })}
                  />
                )}
              />

              {getFormErrorMessage('acidPump')}
            </div>

            <div className="field md:col-4 text-center">
              <Button label="UPDATE" className="p-button-raised p-button-warning" loading={buttonLoading} />
            </div>
            <Messages ref={msg} />
          </form>
        </div>
      </div>
    );
  }
};

export default EditingForm;
