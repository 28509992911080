import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InputSwitchChangeParams } from 'primereact/inputswitch';
import classNames from 'classnames';
import { Button } from 'primereact/button';

interface AppConfigProps {
  inputStyle: string;
  layoutMode: string;
  rippleEffect: boolean;
  onColorModeChange: any;
  onLayoutModeChange: any;
  onInputStyleChange: any;
  onRippleEffect?(e: InputSwitchChangeParams): void;
}

export const AppConfig = (props: AppConfigProps) => {
  const [active, setActive] = useState(false);
  const [scale, setScale] = useState(14);
  const [scales] = useState([12, 13, 14, 15, 16]);
  const [theme, setTheme] = useState('saga-orange');
  const config = useRef<any>(null);
  let outsideClickListener = useRef<any>(null);

  const unbindOutsideClickListener = useCallback(() => {
    if (outsideClickListener.current) {
      document.removeEventListener('click', outsideClickListener.current);
      outsideClickListener.current = null;
    }
  }, []);

  const hideConfigurator = useCallback(
    (event) => {
      setActive(false);
      unbindOutsideClickListener();
      event.preventDefault();
    },
    [unbindOutsideClickListener],
  );

  const bindOutsideClickListener = useCallback(() => {
    if (!outsideClickListener.current) {
      outsideClickListener.current = (event: any) => {
        if (active && isOutsideClicked(event)) {
          hideConfigurator(event);
        }
      };
      document.addEventListener('click', outsideClickListener.current);
    }
  }, [active, hideConfigurator]);

  useEffect(() => {
    if (active) {
      bindOutsideClickListener();
    } else {
      unbindOutsideClickListener();
    }
  }, [active, bindOutsideClickListener, unbindOutsideClickListener]);

  const isOutsideClicked = (event: any) => {
    return !(config.current.isSameNode(event.target) || config.current.contains(event.target));
  };

  const decrementScale = () => {
    setScale((prevState) => --prevState);
  };

  const incrementScale = () => {
    setScale((prevState) => ++prevState);
  };

  useEffect(() => {
    document.documentElement.style.fontSize = scale + 'px';
  }, [scale]);

  const toggleConfigurator = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActive((prevState) => !prevState);
  };

  const configClassName = classNames('layout-config', {
    'layout-config-active': active,
  });

  useEffect(() => {
    let themeElement = document.getElementById('theme-link');
    const themeHref = 'assets/themes/' + theme + '/theme.css';
    if (themeElement !== null) {
      replaceLink(themeElement, themeHref);
    }
  });

  const replaceLink = (linkElement: HTMLElement, href: string, callback?: any) => {
    if (isIE()) {
      linkElement.setAttribute('href', href);

      if (callback) {
        callback();
      }
    } else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true) as Element;

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentElement?.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id ? id : 'unknown');

        if (callback) {
          callback();
        }
      });
    }
  };

  const isIE = () => {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  };

  const changeTheme = (e: React.MouseEvent<HTMLButtonElement>, theme: string, scheme: string) => {
    props.onColorModeChange(scheme);
    setTheme(theme);
  };
  return (
    <div ref={config} className={configClassName} id={'layout-config'}>
      <button className="layout-config-button p-link" id="layout-config-button" onClick={toggleConfigurator}>
        <i className="pi pi-cog"></i>
      </button>
      <Button
        className="p-button-danger layout-config-close p-button-rounded p-button-text"
        icon="pi pi-times"
        onClick={hideConfigurator}
      />

      <div className="layout-config-content">
        <h6 className="mt-0">Component Scale</h6>
        <div className="config-scale">
          <Button
            icon="pi pi-minus"
            onClick={decrementScale}
            className="p-button-text"
            disabled={scale === scales[0]}
          />
          {scales.map((item) => {
            return <i className={classNames('pi pi-circle-on', { 'scale-active': item === scale })} key={item} />;
          })}
          <Button
            icon="pi pi-plus"
            onClick={incrementScale}
            className="p-button-text"
            disabled={scale === scales[scales.length - 1]}
          />
        </div>

        <h6>Themes</h6>
        <div className="grid free-themes">
          <div className="col-3 text-center">
            <button className="p-link" onClick={(e) => changeTheme(e, 'saga-orange', 'light')}>
              Light
            </button>
          </div>
          <div className="col-3 text-center">
            <button className="p-link" onClick={(e) => changeTheme(e, 'vela-orange', 'dim')}>
              Dim
            </button>
          </div>
          <div className="col-3 text-center">
            <button className="p-link" onClick={(e) => changeTheme(e, 'arya-orange', 'dark')}>
              Dark
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
