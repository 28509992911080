import React from 'react';
import { Route, Switch, Redirect, Router } from 'react-router-dom';

import { isValidToken } from '../storage/tokens';
import { Register, ForgotPassword, ResetPassword, Login } from './../view/screen';
import { Protected } from '../Protected';
import history from './History';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /login page
    <Route {...rest} render={(props) => (isValidToken() ? <Component {...props} /> : <Redirect to="/login" />)} />
  );
};

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) => (isValidToken() && restricted ? <Redirect to="/dashboard" /> : <Component {...props} />)}
    />
  );
};

export const allRouter = (
  <Router history={history}>
    <Switch>
      <PublicRoute restricted={true} component={Login} path="/" exact />
      <PublicRoute restricted={true} component={Login} path="/login" exact />
      <PublicRoute restricted={false} component={Register} path="/register" exact />
      <PublicRoute restricted={false} component={ForgotPassword} path="/forgot" exact />
      <PublicRoute restricted={false} component={ResetPassword} path="/reset" />
      <PrivateRoute component={Protected} path="/dashboard" exact />
      <PrivateRoute component={Protected} path="/deviceUpdate" exact />
      <PrivateRoute component={Protected} path="/devices" exact />
      <PrivateRoute component={Protected} path="/deviceDetails" />
      <PrivateRoute component={Protected} path="/users" exact />
    </Switch>
  </Router>
);
