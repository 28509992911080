import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const ConfirmDialogBox = ({
  visible,
  onHide,
  showHeader,
  showMessage,
  confirmMessage,
  cancelRequest,
  successRequest,
}) => {
  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button label="CANCEL" className="p-button-danger" onClick={cancelRequest} />
      <Button label="UPDATE" className="p-button-warning" onClick={successRequest} />
    </div>
  );
  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      position="top"
      footer={dialogFooter}
      showHeader={showHeader}
      breakpoints={{ '960px': '80vw' }}
      style={{ width: '30vw' }}
    >
      <div className="flex justify-content-center flex-column pt-6 px-3">
        <i className="pi pi-question-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
        <h5>{confirmMessage}</h5>
        <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>{showMessage}</p>
      </div>
    </Dialog>
  );
};
export default ConfirmDialogBox;
