import React from 'react';
import ReactDOM from 'react-dom';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './App.scss';
import { App } from './App';

const index = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
};

index();
