import React, { useState, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { getAllUsers } from '../../../api/service/UserService';
import { useQuery } from 'react-query';

export const Users = (props) => {
  const [filters, setFilters] = useState<any | null>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firstname: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    lastname: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    email: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    DeviceId: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });
  const msg = useRef(null);
  const filtersMap = {
    filters: { value: filters, callback: setFilters },
  };

  const { data: users, isLoading: loading, refetch } = useQuery<any, any>('users', getAllUsers, {});

  const onSelectUsers = (e) => {
    if (e.value.device?.serialNumber) {
      window.open(`/deviceDetails?sn=${e.value.device.serialNumber}`, '_blank');
    }
  };

  const onGlobalFilterChange = (event, filtersKey) => {
    console.log('onGlobalFilterChange ', filtersKey, event.target);
    const value = event.target.value;
    let filters1 = { ...filtersMap[filtersKey].value };
    filters1['global'].value = value;
    filtersMap[filtersKey].callback(filters1);
  };

  const renderHeader = (filtersKey) => {
    return (
      <div className="grid">
        <div className="col-6 flex align-items-left justify-content-left">
          <div className="p-fluid">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                type="search"
                value={filters['global'].value || ''}
                onChange={(e) => onGlobalFilterChange(e, filtersKey)}
                placeholder="Global Search"
              />
            </span>
          </div>
        </div>
        <div className="col-6 flex align-items-center justify-content-end">
          <Button icon="pi pi-refresh" onClick={() => refetch()} />
        </div>
      </div>
    );
  };

  const header = renderHeader('filters');
  return (
    <div>
      <div className="card">
        <h5>
          <b>Users List</b>
        </h5>
        <Messages ref={msg} />
        <DataTable
          value={users?.data}
          paginator
          rows={10}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          rowHover
          header={header}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          onSelectionChange={onSelectUsers}
          dataKey="id"
          filterDisplay="menu"
          loading={loading}
          responsiveLayout="scroll"
          emptyMessage="No users found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          selectionMode="single"
        >
          <Column field="id" header="ID" sortable filter filterPlaceholder="Search by user ID" />
          <Column field="firstname" header="First Name" sortable filter filterPlaceholder="Search by first name" />
          <Column field="lastname" header="Last Name" sortable filter filterPlaceholder="Search by last name" />
          <Column field="email" header="Email" sortable filter filterPlaceholder="Search by email" />
          <Column field="DeviceId" header="Device Id" sortable filter filterPlaceholder="Search by device ID" />
        </DataTable>
        <br />
      </div>
    </div>
  );
};
