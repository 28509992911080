import history from '../../routes/History';
import { Tokens } from '../../storage';
import { LOGIN } from '../../routes/RouteConstants';
import { getHeaders, getHeadersWithoutAuth } from '../config/api.config';

// common error response
async function createResponseFromAxiosError(error) {
  // handle  error
  let status;
  let message;
  let data;
  console.log('API error : ', error);
  if (error && error.response) {
    if (error?.response?.status === 401) {
      Tokens.removeLocalData();
      history.push(LOGIN);
    }
    status = error.response.status;
    message = error.message;
    data = error.response.data;
  } else if (error?.status && error?.status === 401) {
  } else if (error?.request) {
    status = 0;
    message = error.message;
  } else if (error.success && error?.data) {
    message = error.data.message;
    data = error.data;
  } else {
    status = -1;
    message = error.message;
  }

  if (data?.message?.message) {
    data.message = data.message.message;
  }
  console.log('API error final res : ', status, message, data);
  return { success: false, data, error: { status, message } };
}

// common success response
function createResponseFromAxiosResponse(response) {
  return response.json().then((responseData) => {
    console.log('success in Request responseData :: ', responseData);
    return { success: true, data: responseData };
  });
}

// common method to make api request using fetch library methods

export const request = async (config) => {
  let response;
  try {
    response = await fetch(config.url, getHeaders(config.method, config.body));
    console.log('response:', response);
    if (response?.status === 200 || response?.status === 201) {
      return createResponseFromAxiosResponse(response);
    } else if (response.statusText === 'Bad Request') {
      return { error: { message: 'Something went wrong (Bad Request). Please contact admin', status: -1 } };
    } else {
      return response.json().then((responseData) => {
        console.log('Error in Request responseData :: ', responseData);
        return createResponseFromAxiosError(responseData);
      });
    }
  } catch (error) {
    console.log('Error in Request :: ', error);
    return createResponseFromAxiosError(error);
  }
};

export const requestWithoutAuth = async (config) => {
  let response;
  try {
    response = await fetch(config.url, getHeadersWithoutAuth(config.method, config.body));
    if (response?.status === 200 || response?.status === 201) {
      return createResponseFromAxiosResponse(response);
    } else if (response.statusText === 'Bad Request') {
      return { error: { message: 'Something went wrong (Bad Request). Please contact admin', status: -1 } };
    } else {
      return response.json().then((responseData) => {
        console.log('Error in Request responseData :: ', responseData);
        return createResponseFromAxiosError(responseData);
      });
    }
  } catch (error) {
    console.log('Error in Request :: ', error);
    return createResponseFromAxiosError(error);
  }
};
