import React, { useState, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';

import { getAllDevices } from '../../../api/service/DeviceService';
import { kibanaLink } from '../../../containers/device/Device.helper';
import { useQuery } from 'react-query';

export const Devices = (props) => {
  const [filters, setFilters] = useState<any | null>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    serialNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmwareVersion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nipperVersion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    isConnected: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    updateStatus: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  });
  const [selectedDevices, setSelectedDevices] = useState(null);
  const msg = useRef(null);
  const filtersMap = {
    filters: { value: filters, callback: setFilters },
  };

  const { data: devices, isLoading: loading, refetch } = useQuery<any, any>('getAllDevices', getAllDevices, {});

  const onSelectDevices = (e) => {
    setSelectedDevices(e.value);
    console.log('selectedDevices: ', e.value);
    window.open(`/deviceDetails?sn=${e.value.serialNumber}`, '_blank');
  };

  const connStatusBodyTemplate = (rowData) => {
    return (
      <i
        className={classNames('pi', {
          'text-green-500 pi-check-circle': rowData.isConnected,
          'text-pink-500 pi-times-circle': !rowData.isConnected,
        })}
      ></i>
    );
  };

  const kibanaBodyTemplate = (rowData) => {
    return (
      <span>
        {
          <a
            style={{ display: 'table-cell' }}
            href={kibanaLink(rowData.serialNumber)}
            target="_blank"
            rel="noopener noreferrer"
          >
            24 Hours
          </a>
        }
      </span>
    );
  };

  const connStatusFilterTemplate = (options) => {
    return (
      <div>
        <span>Connected </span>
        <TriStateCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} />
      </div>
    );
  };

  const onGlobalFilterChange = (event, filtersKey) => {
    console.log('onGlobalFilterChange ', filtersKey, event.target);
    const value = event.target.value;
    let filters1 = { ...filtersMap[filtersKey].value };
    filters1['global'].value = value;
    filtersMap[filtersKey].callback(filters1);
  };

  const renderHeader = (filtersKey) => {
    return (
      <div className="grid">
        <div className="col-6 flex align-items-left justify-content-left">
          <div className="p-fluid">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                type="search"
                value={filters['global'].value || ''}
                onChange={(e) => onGlobalFilterChange(e, filtersKey)}
                placeholder="Global Search"
              />
            </span>
          </div>
        </div>
        <div className="col-6 flex align-items-center justify-content-end">
          <Button icon="pi pi-refresh" onClick={() => refetch()} />
        </div>
      </div>
    );
  };

  const header = renderHeader('filters');

  return (
    <div>
      <div className="card">
        <h5>
          <b>Devices List</b>
        </h5>
        <Messages ref={msg} />
        <DataTable
          value={devices?.data?.response}
          paginator
          rows={10}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          rowHover
          header={header}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          selection={selectedDevices}
          onSelectionChange={onSelectDevices}
          dataKey="id"
          filterDisplay="menu"
          loading={loading}
          responsiveLayout="scroll"
          emptyMessage="No devices found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          selectionMode="single"
        >
          <Column field="id" header="ID" sortable filter filterPlaceholder="Search by device id" />
          <Column
            field="serialNumber"
            header="Serial Number"
            sortable
            filter
            filterPlaceholder="Search by serial number"
          />
          <Column field="firmwareVersion" header="Firmware" sortable filter filterPlaceholder="Search by version" />
          <Column field="nipperVersion" header="Nipper" sortable filter filterPlaceholder="Search by version" />
          <Column field="modes" header="Modes" sortable filterPlaceholder="Search by modes" />
          <Column
            field="isConnected"
            header="Connected to cloud?"
            dataType="boolean"
            bodyClassName="text-center"
            body={connStatusBodyTemplate}
            sortable
            filter
            filterElement={connStatusFilterTemplate}
          />
          {/* <Column field="kibana" header="Kibana data" body={kibanaBodyTemplate} /> */}
        </DataTable>
        <br />
      </div>
    </div>
  );
};
