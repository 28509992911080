import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Menubar } from 'primereact/menubar';
import { logoutUser } from './containers/login/Login.helper';

interface AppTopbarProps {
  piBarDisable: boolean;
  layoutColorMode: string;
  onToggleMenuClick: MouseEventHandler<HTMLButtonElement>;
  onMobileTopbarMenuClick: MouseEventHandler<HTMLButtonElement>;
  onMobileSubTopbarMenuClick: MouseEventHandler<HTMLButtonElement>;
  mobileTopbarMenuActive: boolean;
}

export const AppTopbar = (props: AppTopbarProps) => {
  return (
    <div className="layout-topbar">
      <Link to="/" className="layout-topbar-logo">
        <img
          src={
            props.layoutColorMode === 'light'
              ? 'assets/layout/images/davey_logo1.png'
              : 'assets/layout/images/davey_logo.png'
          }
          alt="logo"
        />
        <span>Lifeguard Service Portal</span>
      </Link>
      <button
        type="button"
        className="p-link  layout-menu-button layout-topbar-button"
        onClick={props.onToggleMenuClick}
      >
        {props.piBarDisable ? '' : <i className="pi pi-bars" />}
      </button>
      <button
        type="button"
        className="p-link layout-topbar-menu-button layout-topbar-button"
        onClick={props.onMobileTopbarMenuClick}
      >
        <i className="pi pi-ellipsis-v" />
      </button>
      <ul
        className={classNames('layout-topbar-menu lg:flex origin-top', {
          'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive,
        })}
      >
        {/*  <li>
          <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
            <i className="pi pi-calendar" />
            <span>Events</span>
          </button>
        </li>
        <li>
          <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
            <i className="pi pi-cog" />
            <span>Settings</span>
          </button>
        </li> */}
        {props.piBarDisable ? (
          ''
        ) : (
          <li>
            <Menubar model={items} />
          </li>
        )}
      </ul>
    </div>
  );
};

const items = [
  {
    icon: 'pi pi-user',
    items: [
      {
        label: 'profile',
        icon: 'pi pi-user',
      },
      {
        label: 'logout',
        icon: 'pi pi-sign-out',
        command: () => {
          logoutUser();
        },
      },
    ],
  },
];
