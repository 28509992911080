import { request } from '../request/axios.request';
import { appConfig, environment } from '../../Config';
import {
  ALL_DEVICE_FW,
  ALL_DEVICE_FW_DEV,
  LATEST_STABLE_VERSIONS,
  LATEST_BETA_VERSIONS,
  LATEST_BETA_VERSIONS_DEV,
  LATEST_STABLE_VERSIONS_DEV,
  UPDATE_DEVICES,
  UPDATE_DEVICES_DEV,
  ALL_DEVICE,
  ALL_DEVICE_DEV,
  SEARCH_DEVICES,
  ATTACH_DEVICE,
  ATTACH_DEVICE_DEV,
  DETACH_DEVICE,
  DETACH_DEVICE_DEV,
  DEVICE_LOGS,
  DEVICE_HISTORY,
  DEVICE_HISTORY_DEV,
  DEVICE_CURRENT_SHADOW,
  EDIT_SCHEDULES,
  EDIT_SCHEDULES_DEV,
  CHANGE_MODE,
  CHANGE_MODE_DEV,
  CHANGE_TARGETS,
  CHANGE_TARGETS_DEV,
  UPDATE_PARAMS,
  UPDATE_PARAMS_DEV,
  GET_ALARMS,
  GET_ALARMS_DEV,
  GET_CHART_DEVICE_LOGS,
  GET_CHART_DEVICE_LOGS_DEV,
  CONNECTED_DEVICE_COUNT,
  CONNECTED_DEVICE_COUNT_DEV,
  GET_RECENT_ALARMS_DEV,
  GET_RECENT_ALARMS,
  GET_RECENT_DEVICE_HISTORY_DEV,
  GET_RECENT_DEVICE_HISTORY,
  GET_FW_UPDATE_STATUS_DEV,
  GET_FW_UPDATE_STATUS,
} from '../routes/route';
import {
  UpdateDevicePayload,
  attachDetachPayload,
  editSchedulesPayload,
  changeModePayload,
  changeTargetsPayload,
  updateParamsPayload,
} from './service.types';

export const enableMockData = () => {
  if (environment === 'dev') {
    return true;
  } else {
    return false;
  }
};
export const getAllDevicesWithFW = () => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-lg']}${ALL_DEVICE_FW_DEV}`,
      method: 'get',
      body: null,
    });
  }
  return request({ url: `${appConfig[environment].backendURL}${ALL_DEVICE_FW}`, method: 'get', body: null });
};

export const getAllDevices = () => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-lg']}${ALL_DEVICE_DEV}`,
      method: 'get',
      body: null,
    });
  }
  return request({ url: `${appConfig[environment].backendURL}${ALL_DEVICE}`, method: 'get', body: null });
};

export const getLatestStableVersions = (product: string) => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-fw']}${LATEST_STABLE_VERSIONS_DEV}${product}`,
      method: 'get',
      body: null,
    });
  }
  return request({
    url: `${appConfig[environment].backendURL}${LATEST_STABLE_VERSIONS}${product}`,
    method: 'get',
    body: null,
  });
};

export const getLatestBetaVersions = (product: string) => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-fw']}${LATEST_BETA_VERSIONS_DEV}${product}`,
      method: 'get',
      body: null,
    });
  }
  return request({
    url: `${appConfig[environment].backendURL}${LATEST_BETA_VERSIONS}${product}`,
    method: 'get',
    body: null,
  });
};

export const updateDeviceAPI = (payload: UpdateDevicePayload) => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-fw']}${UPDATE_DEVICES_DEV}`,
      method: 'post',
      body: payload,
    });
  }
  return request({
    url: `${appConfig[environment].backendURL}${UPDATE_DEVICES}`,
    method: 'post',
    body: payload,
  });
};

export const getDeviceDetailsAPI = (serialNumbers: string[]) => {
  const payload = {
    serialNumbers: serialNumbers,
  };
  return request({ url: `${appConfig[environment].backendURL}${SEARCH_DEVICES}`, method: 'post', body: payload });
};

export const attachDeviceToUser = (payload: attachDetachPayload) => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-lg']}${ATTACH_DEVICE_DEV}`,
      method: 'post',
      body: payload,
    });
  }
  return request({ url: `${appConfig[environment].backendURL}${ATTACH_DEVICE}`, method: 'post', body: payload });
};

export const detachDeviceFromUser = (payload: attachDetachPayload) => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-lg']}${DETACH_DEVICE_DEV}`,
      method: 'post',
      body: payload,
    });
  }
  return request({ url: `${appConfig[environment].backendURL}${DETACH_DEVICE}`, method: 'post', body: payload });
};

export const deviceLogsAPI = (sn: string) => {
  return request({ url: `${appConfig[environment].backendURL}${DEVICE_LOGS}${sn}`, method: 'get', body: null });
};

export const deviceHistoryAPI = (sn: string) => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-lg']}${DEVICE_HISTORY_DEV}${sn}`,
      method: 'get',
      body: null,
    });
  }
  return request({ url: `${appConfig[environment].backendURL}${DEVICE_HISTORY}${sn}`, method: 'get', body: null });
};

export const deviceCurrentShadowAPI = (sn: string) => {
  return request({
    url: `${appConfig[environment].backendURL}${DEVICE_CURRENT_SHADOW}${sn}`,
    method: 'get',
    body: null,
  });
};

export const editSchedulesAPI = (payload: editSchedulesPayload) => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-lg']}${EDIT_SCHEDULES_DEV}`,
      method: 'post',
      body: payload,
    });
  }
  return request({ url: `${appConfig[environment].backendURL}${EDIT_SCHEDULES}`, method: 'post', body: payload });
};

export const changeModeAPI = (payload: changeModePayload) => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-lg']}${CHANGE_MODE_DEV}`,
      method: 'post',
      body: payload,
    });
  }
  return request({ url: `${appConfig[environment].backendURL}${CHANGE_MODE}`, method: 'post', body: payload });
};

export const changeTargetsAPI = (payload: changeTargetsPayload) => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-lg']}${CHANGE_TARGETS_DEV}`,
      method: 'post',
      body: payload,
    });
  }
  return request({ url: `${appConfig[environment].backendURL}${CHANGE_TARGETS}`, method: 'post', body: payload });
};

export const updateParamsAPI = (payload: updateParamsPayload) => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-lg']}${UPDATE_PARAMS_DEV}`,
      method: 'post',
      body: payload,
    });
  }
  return request({ url: `${appConfig[environment].backendURL}${UPDATE_PARAMS}`, method: 'post', body: payload });
};

export const deviceAlarmsAPI = (sn: string) => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-lg-note']}${GET_ALARMS_DEV}`,
      method: 'get',
      body: null,
    });
  }
  return request({ url: `${appConfig[environment].backendURL}${GET_ALARMS}${sn}`, method: 'get', body: null });
};

export const chartDeviceLogsAPI = (sn: string) => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-lg-note']}${GET_CHART_DEVICE_LOGS_DEV}${sn}`,
      method: 'get',
      body: null,
    });
  }
  return request({
    url: `${appConfig[environment].backendURL}${GET_CHART_DEVICE_LOGS}${sn}`,
    method: 'get',
    body: null,
  });
};

export const connectedDeviceCountAPI = () => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-lg']}${CONNECTED_DEVICE_COUNT_DEV}`,
      method: 'get',
      body: null,
    });
  }
  return request({
    url: `${appConfig[environment].backendURL}${CONNECTED_DEVICE_COUNT}`,
    method: 'get',
    body: null,
  });
};

export const recentAlarmsAPI = (limit: number) => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-lg-note']}${GET_RECENT_ALARMS_DEV}${limit}`,
      method: 'get',
      body: null,
    });
  }
  return request({
    url: `${appConfig[environment].backendURL}${GET_RECENT_ALARMS}${limit}`,
    method: 'get',
    body: null,
  });
};

export const recentDeviceHistoryAPI = (limit: number) => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-lg']}${GET_RECENT_DEVICE_HISTORY_DEV}${limit}`,
      method: 'get',
      body: null,
    });
  }
  return request({
    url: `${appConfig[environment].backendURL}${GET_RECENT_DEVICE_HISTORY}${limit}`,
    method: 'get',
    body: null,
  });
};

export const getFWUpdateStatusAPI = () => {
  if (environment === 'dev') {
    return request({
      url: `${appConfig[environment]['backendURL-lg']}${GET_FW_UPDATE_STATUS_DEV}`,
      method: 'get',
      body: null,
    });
  }
  return request({
    url: `${appConfig[environment].backendURL}${GET_FW_UPDATE_STATUS}`,
    method: 'get',
    body: null,
  });
};
