import history from '../../routes/History';
import { removeLocalData } from '../../storage/tokens';

export const forwardTo = (location) => {
  return history.push(location);
};

export const logoutUser = () => {
  removeLocalData();
  forwardTo('/login');
};
