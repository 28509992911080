import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'primereact/chart';
import {
  getLatestStableVersions,
  connectedDeviceCountAPI,
  recentAlarmsAPI,
  recentDeviceHistoryAPI,
} from '../../../api/service/DeviceService';
import { PRODUCT } from '../../../api/routes/route';
import { appConfig, environment, LG_ERRORS, CHART_LG_ERRORS } from '../../../Config';

export const Dashboard = () => {
  const flow_count = useRef(1);
  const ph_count = useRef(1);
  const orp_count = useRef(1);
  const [stableFW, setStableFW] = useState('...');
  const [stableNipperVersion, setStableNipperVersion] = useState('...');
  const [activeDevices, setActiveDevices] = useState(null);
  const [recentAlarms, setRecentAlarms] = useState([]);
  const [recentDeviceHistory, setRecentDeviceHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [lightOptions] = useState({
    plugins: {
      legend: {
        labels: {
          color: '#495057',
        },
      },
    },
  });

  useEffect(() => {
    // Latest Stable lifeguard firmware versions
    getLatestStableVersions(PRODUCT.LIFEGUARD).then((payload) => {
      console.log('getLatestStableVersions lifeguard: ', payload);
      if (payload?.data?.response) {
        return setStableFW(payload?.data?.response[0]);
      }
      return 'Error retrieving versions';
    });

    // Latest Stable nipper versions
    getLatestStableVersions(PRODUCT.NIPPER).then((payload) => {
      console.log('getLatestStableVersions nipper: ', payload);
      if (payload?.data?.response) {
        return setStableNipperVersion(payload?.data?.response[0]);
      }
      return 'Error retrieving versions';
    });

    // Connected device count
    connectedDeviceCountAPI().then((payload) => {
      console.log('connectedDeviceCountAPI: ', payload);
      if (payload?.data?.response) {
        return setActiveDevices(payload?.data?.response);
      }
      return 'Error retrieving Device count';
    });

    // Recent Alarms with limit of 5
    recentAlarmsAPI(5).then((payload) => {
      console.log('recentAlarmsAPI: ', payload);
      if (payload?.data?.response) {
        (payload?.data?.response || []).forEach((row) => {
          console.log(row);
          if (LG_ERRORS.FLOW_ERROR === row.type) {
            flow_count.current = flow_count.current + 1;
          } else if (LG_ERRORS.PH_ERROR === row.type) {
            ph_count.current = ph_count.current + 1;
          } else if (LG_ERRORS.ORP_ERROR === row.type) {
            orp_count.current = orp_count.current + 1;
          }
        });
        setChartData({
          labels: CHART_LG_ERRORS,
          datasets: [
            {
              data: [Number(ph_count.current), Number(orp_count.current), Number(flow_count.current)],
              backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726'],
              hoverBackgroundColor: ['#64B5F6', '#81C784', '#FFB74D'],
            },
          ],
        });
        setLoading(false);
        return setRecentAlarms(payload?.data?.response);
      }
      return 'Error retrieving Alarms';
    });

    // Recent device history with limit of 10
    recentDeviceHistoryAPI(10).then((payload) => {
      console.log('recentDeviceHistoryAPI: ', payload);
      if (payload?.data?.response) {
        return setRecentDeviceHistory(payload?.data?.response);
      }
      return 'Error retrieving Alarms';
    });
  }, []);

  return (
    <div className="grid">
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Firmware version</span>
              <div className="text-900 font-medium">
                Firmware : {stableFW}; Nipper : {stableNipperVersion}
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-blue-100 border-round"
              style={{ width: '2.5rem', height: '2.5rem' }}
            >
              <i className="pi pi-cog text-blue-500 text-xl" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">iOS version</span>
              <div className="text-900 font-medium">{appConfig[environment].iosVersion}</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-orange-100 border-round"
              style={{ width: '2.5rem', height: '2.5rem' }}
            >
              <i className="pi pi-apple text-orange-500 text-xl" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Android version</span>
              <div className="text-900 font-medium">{appConfig[environment].androidVersion}</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-cyan-100 border-round"
              style={{ width: '2.5rem', height: '2.5rem' }}
            >
              <i className="pi pi-android text-cyan-500 text-xl" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Active Devices</span>
              <div className="text-900 font-medium">{activeDevices}</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-purple-100 border-round"
              style={{ width: '2.5rem', height: '2.5rem' }}
            >
              <i className="pi pi-cloud text-purple-500 text-xl" />
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 xl:col-6">
        <div className="card">
          <div className="flex justify-content-between align-items-center mb-5">
            <h5>Recent Alarms</h5>
          </div>
          <ul className="list-none p-0 m-0">
            {(recentAlarms || []).map((alarm, index) => {
              return (
                <li
                  key={index}
                  className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
                >
                  <div>
                    <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{alarm?.type.replace(/_/g, ' ')}</span>
                    <div className="mt-1 text-600">{alarm?.Device?.serialNumber}</div>
                    <div className="mt-1 text-600">{new Date(alarm?.updatedAt).toLocaleString()}</div>
                  </div>
                  <div className="mt-2 md:mt-0 flex align-items-center">
                    <span className="text-orange-500 ml-3 font-medium">{alarm.value}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="card">
          <h5>Device Overview</h5>
          {loading ? (
            <span>Loading...</span>
          ) : (
            <Chart type="pie" data={chartData} options={lightOptions} style={{ position: 'relative', width: '40%' }} />
          )}
        </div>
      </div>

      <div className="col-12 xl:col-6">
        <div className="card">
          <div className="flex align-items-center justify-content-between mb-4">
            <h5>Recent Activities</h5>
          </div>
          <ul className="p-0 mx-0 mt-0 mb-4 list-none">
            {(recentDeviceHistory || []).map((dh, index) => {
              return (
                <li key={index} className="flex align-items-center py-2 border-bottom-1 surface-border">
                  <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                    <i className="pi pi-info text-blue-500" />
                  </div>
                  <span className="text-900 line-height-3">
                    <span className="text-900">
                      {dh?.eventType.replace(/_/g, ' ')} : {dh?.Device?.serialNumber}
                    </span>
                    <br />
                    <span className="text-500">{new Date(dh?.updatedAt).toLocaleString()}</span>
                    <br />
                    <span className="text-500">{dh?.event?.request}</span>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
