import { DEFAULT_STATE } from './Device.state';

const INITIAL_STATE = DEFAULT_STATE;

const DeviceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default DeviceReducer;
