// DEV Routes
export const LOGIN_DEV = 'login';
export const REGISTER_DEV = 'register';
export const ALL_DEVICE_FW_DEV = 'all/fw';
export const ALL_DEVICE_DEV = 'all';
export const LATEST_STABLE_VERSIONS_DEV = 'firmware-versions?product=';
export const LATEST_BETA_VERSIONS_DEV = 'beta-firmware-versions?product=';
export const UPDATE_DEVICES_DEV = 'update-devices';
export const FORGOT_PASSWORD_DEV = 'reset';
export const UPDATE_PASSWORD_DEV = 'update/password';
export const SEARCH_DEVICES_DEV = 'search-devices';
export const DETACH_DEVICE_DEV = 'detach-device';
export const ATTACH_DEVICE_DEV = 'attach-device';
export const DEVICE_LOGS_DEV = 'logs/';
export const DEVICE_HISTORY_DEV = 'device-history/';
export const DEVICE_CURRENT_SHADOW_DEV = 'shadow/';
export const EDIT_SCHEDULES_DEV = 'edit-schedule';
export const CHANGE_MODE_DEV = 'change-mode';
export const CHANGE_TARGETS_DEV = 'change-target';
export const UPDATE_PARAMS_DEV = 'update-params';
export const GET_ALARMS_DEV = 'notifications?id=';
export const GET_CHART_DEVICE_LOGS_DEV = 'chart-logs/';
export const CONNECTED_DEVICE_COUNT_DEV = 'connected-device-count';
export const GET_RECENT_ALARMS_DEV = 'recent-alarms/';
export const GET_RECENT_DEVICE_HISTORY_DEV = 'recent-device-history/';
export const GET_FW_UPDATE_STATUS_DEV = 'fw-update-status';
export const ALL_USERS_DEV = 'allUsers';

// All other environment Routes
export const LOGIN = 'user/login';
export const REGISTER = 'user/register';
export const FORGOT_PASSWORD = 'user/reset';
export const UPDATE_PASSWORD = 'user/update/password';
export const ALL_DEVICE_FW = 'lg/all/fw';
export const ALL_DEVICE = 'lg/all';
export const SEARCH_DEVICES = 'lg/search-devices';
export const DETACH_DEVICE = 'lg/detach-device';
export const ATTACH_DEVICE = 'lg/attach-device';
export const DEVICE_HISTORY = 'lg/device-history/';
export const DEVICE_CURRENT_SHADOW = 'lg/shadow/';
export const EDIT_SCHEDULES = 'lg/edit-schedule';
export const CHANGE_MODE = 'lg/change-mode';
export const CHANGE_TARGETS = 'lg/change-target';
export const UPDATE_PARAMS = 'lg/update-params';
export const CONNECTED_DEVICE_COUNT = 'lg/connected-device-count';
export const GET_RECENT_DEVICE_HISTORY = 'lg/recent-device-history/';
export const GET_FW_UPDATE_STATUS = 'lg/fw-update-status';
export const LATEST_STABLE_VERSIONS = 'fw/firmware-versions?product=';
export const LATEST_BETA_VERSIONS = 'fw/beta-firmware-versions?product=';
export const UPDATE_DEVICES = 'fw/update-devices';
export const DEVICE_LOGS = 'lg-notification/logs/';
export const GET_ALARMS = 'lg-notification/notifications?id=';
export const GET_CHART_DEVICE_LOGS = 'lg-notification/chart-logs/';
export const GET_RECENT_ALARMS = 'lg-notification/recent-alarms/';
export const ALL_USERS = 'user/allUsersWithDevices';

export const ENV_LIST = {
  STABLE: 'Stable',
  BETA: 'Beta',
};

export const PRODUCT = {
  LIFEGUARD: 'lifeguard',
  NIPPER: 'nipper',
};
