import { configureStore } from '@reduxjs/toolkit';
import rootSaga from './Root.saga';
import createSagaMiddleware from '@redux-saga/core';

import LoginReducer from '../containers/login/Login.reducer';
import DeviceReducer from '../containers/device/Device.reducer';
import UserReducer from '../containers/user/User.reducer';
import { reducer as formReducer } from 'redux-form';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: LoginReducer,
    form: formReducer,
    device: DeviceReducer,
    users: UserReducer,
  },
  middleware: [sagaMiddleware],
});
sagaMiddleware.run(rootSaga);

export default store;
