export const environment = process.env.REACT_APP_ENVIRONMENT;

export const appConfig = {
  dev: {
    'backendURL-user': 'http://localhost:3000/lgportal-uat/',
    'backendURL-lg': 'http://localhost:3002/lgportal-uat/',
    'backendURL-admin': 'http://localhost:3003/lgportal-uat/',
    'backendURL-fw': 'http://localhost:3004/lgportal-uat/',
    'backendURL-lg-note': 'http://localhost:3005/lgportal-uat/',
    backendURL: 'https://lgportal.uat.dwprod.co/',
    product: 'Lifeguard-uat',
    serialPlaceHolder: 'Search: dlgt-xxxxxxxx, dlgt-xxxxxxxx, ...',
    iosVersion: '3.0.3, Build 152',
    androidVersion: '3.0.3',
  },
  'lgportal-prod': {
    backendURL: 'https://lgportal-api.dwprod.co/',
    product: 'Lifeguard',
    serialPlaceHolder: 'Search: dwlg-xxxxxxxx, dwlg-xxxxxxxx, ...',
    iosVersion: '3.0.3, Build 152',
    androidVersion: '3.0.3',
  },
  'lgportal-uat': {
    backendURL: 'https://lgportal-api.uat.dwprod.co/',
    product: 'Lifeguard-uat',
    serialPlaceHolder: 'Search: dlgt-xxxxxxxx, dlgt-xxxxxxxx, ...',
    iosVersion: '3.0.3, Build 152',
    androidVersion: '3.0.3',
  },
  'hwportal-prod': {
    backendURL: 'https://hwportal-api.henden.co/',
    product: 'Henden',
    serialPlaceHolder: 'Search: hwqh-xxxxxxxx, hwqh-xxxxxxxx, ...',
    iosVersion: '3.1.1, Build 22',
    androidVersion: '3.1.1',
  },
};

export const CHART_LG_ERRORS = ['PH ERROR', 'ORP ERROR', 'FLOW ERROR'];
export const LG_ERRORS = {
  PH_ERROR: 'PH_ERROR',
  ORP_ERROR: 'ORP_ERROR',
  FLOW_ERROR: 'FLOW_ERROR',
};
