import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import EditingForm from './EditingForm';

const Devices = ({ shadow, device, dispatchToParent }) => {
  console.log('shadow-device data: ', shadow, device);

  const shadowCopy = shadow?.formattedShadow;
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogHeader, setDialogHeader] = useState('');
  const [formType, setTypeForm] = useState('');
  const scheduleTemplate = () => {
    const sch1 = shadowCopy?.schedules[0];
    const sch2 = shadowCopy?.schedules[1];
    const daysFormat1 = sch1.days === '1111111' ? 'Every day' : sch1.days;
    const daysFormat2 = sch2.days === '1111111' ? 'Every day' : sch2.days;
    return (
      <div>
        <span>
          <b>Timer 1:</b>
        </span>
        <br />
        <span>
          <b>ON:</b> {sch1.on}&nbsp;
        </span>
        <span>
          <b>OFF:</b> {sch1.off}&nbsp;
        </span>
        <span>
          <b>Days(mon-sun):</b> {daysFormat1}&nbsp;
        </span>
        <span>
          <b>Speed(2-10):</b> {sch1.speed}
        </span>
        <br />
        <br />
        <span>
          <b>Timer 2:</b>
        </span>
        <br />
        <span>
          <b>ON:</b> {sch2.on}&nbsp;
        </span>
        <span>
          <b>OFF:</b> {sch2.off}&nbsp;
        </span>
        <span>
          <b>Days(mon-sun):</b> {daysFormat2}&nbsp;
        </span>
        <span>
          <b>Speed(2-10):</b> {sch2.speed}
        </span>
      </div>
    );
  };

  const formatVSD = (value: string) => {
    const splitValues = value.split(',');
    const status = Number(splitValues[0]);
    const speed = splitValues[1];
    return `status: ${status === 1 ? 'ON' : 'OFF'} and Default speed: ${speed}`;
  };

  const openDialog = (headerName, type) => {
    setDialogHeader(headerName);
    setTypeForm(type);
    setDialogVisible(true);
  };

  const closeDialog = () => {
    setDialogVisible(false);
  };

  return (
    <div className="card">
      <div className="font-medium text-3xl text-900 mb-3">Device Parameters</div>
      <div className="text-500 mb-5">IoT shadow parameters</div>
      <div className="card">
        <div className="surface-0">
          <div className="flex justify-content-between align-items-center mb-5">
            <div className="text-900 mb-5">Schedules</div>
            <div>
              <Button
                label="Edit"
                icon="pi pi-pencil"
                className="p-button-text"
                onClick={(e) => openDialog('Edit schedules', 'edit_schedules')}
              />
            </div>
          </div>
          <ul className="list-none p-0 m-0">
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Schedules</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{scheduleTemplate()}</div>
            </li>
          </ul>
        </div>
      </div>
      <div className="card">
        <div className="surface-0">
          <div className="flex justify-content-between align-items-center mb-5">
            <div className="text-900 mb-5"> Modes</div>
            <div>
              <Button
                label="Edit"
                icon="pi pi-pencil"
                className="p-button-text"
                onClick={(e) => openDialog('Update Modes', 'update_modes')}
              />
            </div>
          </div>
          <ul className="list-none p-0 m-0">
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Modes</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{shadowCopy.modes.toString()}</div>
            </li>
          </ul>
        </div>
      </div>
      <div className="card">
        <div className="surface-0">
          <div className="flex justify-content-between align-items-center mb-5">
            <div className="text-900 mb-5">Targets</div>
            <div>
              <Button
                label="Edit"
                icon="pi pi-pencil"
                className="p-button-text"
                onClick={(e) => openDialog('Update Targets', 'targets')}
              />
            </div>
          </div>
          <ul className="list-none p-0 m-0">
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">VSD</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{formatVSD(shadowCopy.setVsd)}</div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">PH setpoint</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{shadowCopy.phSetpoint}</div>
              <div className="w-6 md:w-2 flex justify-content-end"></div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">PH status</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                {shadowCopy.phConnected ? 'Connected' : 'Not connected'}
              </div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">ORP setpoint</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{shadowCopy.orpSetpoint}</div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">ORP status</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                {shadowCopy.orpConnected ? 'Connected' : 'Not connected'}
              </div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Temp setpoint</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{shadowCopy.tempSetpoint}</div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Temp status</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                {shadowCopy.tempConnected ? 'Connected' : 'Not connected'}
              </div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Salinity setpoint</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{shadowCopy.salinitySetpoint}</div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Salinity status</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                {shadowCopy.salinityConnected ? 'Connected' : 'Not connected'}
              </div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Boost Duration setpoint</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{shadowCopy.setBoostDuration}</div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Backwash Duration setpoint</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                {shadowCopy.setBackwashDuration}
              </div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Cell output</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{shadowCopy.setCellOutput}</div>
            </li>
          </ul>
        </div>
      </div>
      <div className="card">
        <div className="surface-0">
          <div className="flex justify-content-between align-items-center mb-5">
            <div className="text-900 mb-5"> Other Parameters</div>
            <div>
              <Button
                label="Edit"
                icon="pi pi-pencil"
                className="p-button-text"
                onClick={(e) => openDialog('Update Parameters', 'params')}
              />
            </div>
          </div>
          <ul className="list-none p-0 m-0">
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Heat pump</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{device.heatPump}</div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Acid pump</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                {device.acidPump ? 'Connected' : 'Not connected'}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="card">
        <div className="surface-0">
          <div className="flex justify-content-between align-items-center">
            <div className="text-900 mb-5"> Configuration</div>
          </div>
          <ul className="list-none p-0 m-0">
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Cell Size</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{device.nipperCell} grams</div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Pool Size</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{device.poolSize} litres</div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Region</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                {device.region === 1 ? 'Europe' : 'ANZ'}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <Dialog
        header={dialogHeader}
        visible={dialogVisible}
        style={{ width: '75vw' }}
        maximizable
        modal
        contentStyle={{ height: '30vw' }}
        onHide={closeDialog}
      >
        <EditingForm name={formType} shadow={shadow} device={device} />
      </Dialog>
    </div>
  );
};

export default Devices;
