// import SimpleCrypto from 'simple-crypto-js';

export function getUserType() {
  const type = localStorage.getItem('type');
  return type;
}

export function setUserType(type) {
  localStorage.removeItem('type');
  localStorage.setItem('type', type);
  return type;
}

export function setUserDetails(data) {
  // const decryptType = new SimpleCrypto('123654*/4514545454');
  localStorage.setItem('typeData', JSON.stringify(data));
}

export const getUserDetails = () => {
  if (localStorage.getItem('typeData') === null) {
    return '';
  }
  // const decryptType = new SimpleCrypto('123654*/4514545454');
  const userData = JSON.parse(localStorage.getItem('typeData'));
  return userData;
};
