import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import { useForm, Controller } from 'react-hook-form';
import { Messages } from 'primereact/messages';
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { AppTopbar } from '../../../AppTopbar';
import { AppFooter } from '../../../AppFooter';
import { AppConfig } from '../../../AppConfig';
import { authenticate } from '../../../containers/login/Login.action';

import PrimeReact from 'primereact/api';
import './Login.scss';

export const Login = (props) => {
  const [layoutMode, setLayoutMode] = useState<string>('static');
  const [layoutColorMode, setLayoutColorMode] = useState<string>('dark');
  const [inputStyle, setInputStyle] = useState<string>('outlined');
  const [ripple, setRipple] = useState<boolean>(true);
  const [overlayMenuActive, setOverlayMenuActive] = useState<boolean>(false);
  const [mobileMenuActive, setMobileMenuActive] = useState<boolean>(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const staticMenuInactive = true;
  const reRef = useRef<ReCAPTCHA>();
  PrimeReact.ripple = true;
  const msg = useRef(null);
  let menuClick = false;
  let mobileTopbarMenuClick = false;
  const dispatch = useDispatch();
  const loginError = useSelector((state: any) => state.auth.error || '');

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, 'body-overflow-hidden');
    } else {
      removeClass(document.body, 'body-overflow-hidden');
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    if (loginError?.message) {
      console.log('login error: ', loginError);
      setLoading(false);
      msg.current.show({
        severity: 'error',
        sticky: true,
        content: <React.Fragment>{loginError.message}</React.Fragment>,
      });
    }
  }, [loginError]);

  const onInputStyleChange = (inputStyle: string) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e: any) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode: string) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode: string) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onMobileTopbarMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const addClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.add(className);
    else element.className += ' ' + className;
  };

  const removeClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' ',
      );
  };

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': ripple === false,
    'layout-theme-light': layoutColorMode === 'light',
  });

  //const [showMessage, setShowMessage] = useState(false);

  const defaultValues = {
    email: '',
    password: '',
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  const onSubmit = async (data) => {
    console.log('submit form: ', data);
    setLoading(true);
    msg.current.clear();
    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    data.token = token;
    dispatch(authenticate(data));

    //reset();
  };

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  /*  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} />
    </div>
  ); */
  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <AppTopbar
        piBarDisable={true}
        onToggleMenuClick={null}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      {/* <div className="layout-sidebar" onClick={onSidebarClick}>
        <AppMenu model={null} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
      </div> */}

      <div className="layout-main-container">
        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} size="invisible" ref={reRef} />
        <div className="layout-main">
          <div className="form-look">
            <div className="flex justify-content-center">
              <div className="card">
                <h5 className="text-center">Login</h5>
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                  <Messages ref={msg} />
                  <div className="field">
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-envelope" />
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: 'Email is required.',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Invalid email address. E.g. example@email.com',
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            className={classNames({ 'p-invalid': fieldState.invalid })}
                          />
                        )}
                      />
                      <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>
                        Email*
                      </label>
                    </span>
                    {getFormErrorMessage('email')}
                  </div>
                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name="password"
                        control={control}
                        rules={{ required: 'Password is required.' }}
                        render={({ field, fieldState }) => (
                          <Password
                            id={field.name}
                            {...field}
                            feedback={false}
                            toggleMask
                            className={classNames({ 'p-invalid': fieldState.invalid })}
                          />
                        )}
                      />
                      <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>
                        Password*
                      </label>
                    </span>
                    {getFormErrorMessage('password')}
                  </div>
                  <Button type="submit" label="Submit" className="mt-2" loading={loading} />
                  <div>
                    <span className="rgs">{<a href="/register">Register Now</a>}</span>
                  </div>{' '}
                  <div>
                    <span className="psw">
                      {' '}
                      <a href="/forgot">Forgot password?</a>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <AppFooter layoutColorMode={layoutColorMode} />
      </div>

      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        onColorModeChange={onColorModeChange}
      />

      <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  );
};
