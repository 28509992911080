import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import history from './routes/History';
import store from './store/Store.factory';
import { allRouter } from './routes/Route';
// Create a client
const queryClient = new QueryClient();

export function App() {
  return (
    // Provide the client to your App
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Router history={history}>{allRouter}</Router>
      </Provider>
    </QueryClientProvider>
  );
}
